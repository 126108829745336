import axios from "axios";
import Notiflix from "notiflix";

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const sendEmail = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_REQUEST });
            let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
            let response = await axios.post(dataUrl, data);
            dispatch({ type: SEND_EMAIL_SUCCESS, payload: response.data });
            Notiflix.Notify.success('Email Sent');
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_EMAIL_FAILURE, payload: error });
        }
    }
};