import * as textActions from './textActions';

export const pendantTextInfo = 'pendantText';

const initialState = {
    line_one: "",
    line_two: "",
    line_three: "",
    errMessage: ""
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case textActions.ADD_PENDANT_TEXT:
            sessionStorage.setItem('data', JSON.stringify(payload ? payload : ""))
            // sessionStorage.setItem('lineOne', payload.line_one ? payload.line_one : "")
            // sessionStorage.setItem('lineTwo', payload.line_two ? payload.line_two : "")
            // sessionStorage.setItem('lineThree', payload.line_three ? payload.line_three : "")
            return {
                ...state,
                line_one: payload.name,
                line_two: payload.number,
                line_three: payload.third,
                qty_Data: payload.qty,
            }

        case textActions.ADD_PENDANT_TEXT_FAILURE:
            return {
                ...state,
                errMessage: payload
            }

        case textActions.CLEAR_PENDANT_TEXT:
            sessionStorage.removeItem('data');
            return {
                ...state,
                line_one: "",
                line_two: "",
                line_three: ""
            }

        default: return state;
    }
};