export const ADD_PENDANT_TEXT = 'ADD_PENDANT_TEXT';
export const ADD_PENDANT_TEXT_FAILURE = 'ADD_PENDANT_TEXT_FAILURE';

export const CLEAR_PENDANT_TEXT = 'CLEAR_PENDANT_TEXT';

export const addText = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_PENDANT_TEXT, payload: data });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_PENDANT_TEXT_FAILURE, payload: error });
        }
    }
};

export const clearText = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CLEAR_PENDANT_TEXT });
        } catch (error) {
            console.error(error);

        }
    }
};