import React from "react";
import { Route, Redirect } from "react-router-dom";

let PrivateRoute = ({ component: Component, ...rest }) => {

  const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))
  return (
    <Route
      {...rest}
      render={props => (token?.body?.id_token ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)}
    />
  );
};
export default PrivateRoute;

