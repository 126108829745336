import * as userAddressActions from './userAddressActions';

export const userAddressFeatureKey = 'userInfo';

const initialState = {
    loading: false,
    errMessage: ""
};

export const reducer = (state = initialState, action) => {
    let { type, payload } = action;
    switch (type) {
        case userAddressActions.USER_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case userAddressActions.USER_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case userAddressActions.USER_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload
            };

        default: return state;
    }
};