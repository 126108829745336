import React from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import GetApp from "../get_app_id/GetApp";
import GetId from "../get_app_id/GetId";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png";
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import "./help.css";

const Help = () => {
  return (
    <>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container className="help">
        <div className="help_content mx-auto">
          <h2>Help topics</h2>
          <p>Maybe some of these topics can help:</p>
          <ul className="mt-4" style={{ textDecoration: "underline" }}>
            <li className="mt-3">
              <a href="https://netorgft7133382.sharepoint.com/:b:/g/EUTJ7pCwaNhKpjI9B_mcVp8BW6c2k6ULF0ArvSapqIxqfw?e=PocgHN" target="_blank">Your pet’s public profile</a>
            </li>
            <li className="mt-3">
              <a href="https://netorgft7133382.sharepoint.com/:b:/g/EfBnY9kl3DlJj4SdJvn2gMQBHY4V-nzws1gQ5IGwTdxFBw?e=DzVJyL" target="_blank">Your pet’s calories</a>
            </li>
            <li className="mt-3">
              <a href="https://netorgft7133382.sharepoint.com/:b:/g/EciijGWHypFAi9BCZaV8bJYBMIHGm__n3kDVb1vjgFRr7g?e=UBvJbu" target="_blank">Roles of Parents & Hosts</a>
            </li>
            <li className="mt-3">
              <a href="https://netorgft7133382.sharepoint.com/:b:/g/EQzKOAjzH-JEhHsvt2-n1NEBW2dvCO6g7CXAGseeBxh8GQ?e=Iqrdol" target="_blank">Using JELLIE ID accessories</a>
            </li>
          </ul>
          <div className="contactus_redirect d-flex flex-column align-items-center">
            <p>Haven’t found what you are looking for?</p>
            <Button tag={Link} to="/contact" className="col-md-8 col-lg-8 p-3" outline onClick={(e) => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}>
              Contact us
            </Button>
          </div>
        </div>
      </Container>
      {/* <Container className="get_app_id help_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="help_get_id">
        <Link to='/JellieID' style={{ textDecoration: "none" }} onClick={(e) => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }} >
          <div className="get_id p-4">
            <div className="paw_icon">
              <div>
                <img src={pawIcon} alt="jellie_app_paw_icon" />
              </div>
            </div>
            <div className="jellieid_info p-3">
              <h2 className="py-3">Get a JELLIE ID</h2>
              <p className="info_sm">
                The easiest way to share info or protect your pet.
              </p>
              <p className="info_lg py-3">
                This smart accessory is easiest way to share info or protect your
                pet.
              </p>
              <ul>
                <li>- Readable by any smartphone camera</li>
                <li>- Links to pet profile with all relevant bio info</li>
                <li>- Masked (secure) contact parents and host</li>
              </ul>
            </div>
            <div className="jellieid_keychain px-4">
              <img src={keychain} alt="jellieid_keychain" />
            </div>
          </div>
        </Link>
      </Container>
    </>
  );
};

export default Help;
