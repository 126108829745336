import * as emailActions from "./emailActions";

export const emailFeatureKey = 'emailInfo';

const initialState = {
    loading: false,
    errMessage: ""
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case emailActions.SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            };

        case emailActions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case emailActions.SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload
            };

        default: return state;
    }
};