import React from 'react';
import ReactDOM from 'react-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <React.Fragment>
    <Auth0Provider response_type="token" cacheLocation='localstorage' domain={process.env.REACT_APP_DOMAIN} clientId={process.env.REACT_APP_CLIENT_ID} redirectUri={`${window.location.origin}`}>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
