import axios from "axios";

export const USER_ADDRESS_REQUEST = 'USER_ADDRESS_REQUEST';
export const USER_ADDRESS_SUCCESS = 'USER_ADDRESS_SUCCESS';
export const USER_ADDRESS_FAILURE = 'USER_ADDRESS_FAILURE';

export const userAddress = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: USER_ADDRESS_REQUEST });
            let dataUrl = `http://127.0.0.1:5000/api/v1/user/create/address`;
            let response = await axios.post(dataUrl, user);
            dispatch({ type: USER_ADDRESS_SUCCESS, payload: response.data });
            // alert
        } catch (error) {
            console.error(error);
            dispatch({ type: USER_ADDRESS_FAILURE, payload: error });
        }
    }
};