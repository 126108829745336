import React from 'react'

const Loading = () => (
  <div className="loader-container d-flex align-items-center justify-content-center loading2">
    <div className="loader">
    </div>
  </div>
)

export default Loading
