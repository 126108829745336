import React from "react";
import Collapsible from "react-collapsible";
import { Container } from "reactstrap";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png"; 
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import { Link } from "react-router-dom";
import "./invalid.css"

const InvalidUrl = () => {
  return (
    <>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container className="invalid">
        <div className="invalid_content mx-auto">
          <h2>Invalid URL</h2>
          <Collapsible
            trigger={[
              <p className="collapse_name">Sorry we cannot find this page! </p>,
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>,
            ]}
          >
            <div className="collapse_inner_content">
              <div className="profile_collapse_row">
                <span className="profile_collapse_row_content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </span>
              </div>
            </div>
          </Collapsible>
        </div>
      </Container>
      <div style={{marginBottom: "200px"}}></div>
      {/* <Container className="get_app_id invalid_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="invalid_get_id">
      <Link to='/JellieID' style={{ textDecoration: "none" }} onClick={(e) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }} >
        <div className="get_id p-4">
          <div className="paw_icon">
            <div>
              <img src={pawIcon} alt="jellie_app_paw_icon" />
            </div>
          </div>
          <div className="jellieid_info p-3">
            <h2 className="py-3">Get a JELLIE ID</h2>
            <p className="info_sm">
              The easiest way to share info or protect your pet.
            </p>
            <p className="info_lg py-3">
              This smart accessory is easiest way to share info or protect your
              pet.
            </p>
            <ul>
              <li>- Readable by any smartphone camera</li>
              <li>- Links to pet profile with all relevant bio info</li>
              <li>- Masked (secure) contact parents and host</li>
            </ul>
          </div>
          <div className="jellieid_keychain px-4">
            <img src={keychain} alt="jellieid_keychain" />
          </div>
        </div>
      </Link>
    </Container>
    </>
  );
};

export default InvalidUrl;
