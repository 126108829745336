import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import applepay from "../../assets/icons/applepay.png";
import paypal from "../../assets/icons/paypal.png";
import "./shippinginfo.css";
import Loading from '../Loading'
import stripeImage from '../../assets/icons/Icon_256w_JellieID_QRCenter.png';
import StripeCheckout from "react-stripe-checkout";
import { makeStripePayment } from "../../redux/payment/paymentActions";
import { useDispatch, useSelector } from 'react-redux';
import { userAddress } from "../../redux/userAddress/userAddressActions";
import { useAuth0 } from "@auth0/auth0-react";
import { jellieIdOrder } from './EmailTemplate';
import * as emailActions from '../../redux/email/emailActions';
import { ALL_USERS } from "../../GraphQL/queries";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER } from "../../GraphQL/mutation";
import * as textReducers from '../../redux/text/textReducers';
import * as paymentReducers from '../../redux/payment/paymentReducers';
import * as paymentActions from '../../redux/payment/paymentActions';
import * as textActions from '../../redux/text/textActions';
import Notiflix from "notiflix";

const ShippingInfo = (props) => {

  const [user, setUser] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "Alabama",
    zip: ""
  });
  const [price, setPrice] = useState();
  const [charge, setCharge] = useState(9.99);
  const [userInfo, setUserInfo] = useState({});
  const [errEmail, setErrEmail] = useState('')
  const [errZip, setErrZip] = useState('')
  const [loadingShaw, setLoadingShaw] = useState(false);
  const [usStates, setUsStates] = useState(['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'])

  const paymentResponseStatus = useSelector(state => state.paymentInfo.resStatus)
  const sucessPayment = useSelector(state => state.paymentInfo.sucess)
  const errMessage = useSelector(state => state.paymentInfo.errMessage)
  const previousProps = useRef({ sucessPayment }).current

  const type = "pendant";
  const qty = useParams().qty;

  useEffect(async () => {
    const getData = JSON.parse(sessionStorage.getItem('data'));
    const name = getData?.name ? getData.name : "";
    const number = getData?.number ? getData.number : "";
    const third = getData?.third ? getData.third : "";
    await dispatch(textActions.addText({ name, number, third }));
    await sessionStorage.removeItem('data');
  }, []);
  useEffect(() => {
    if (type === "sticker") {
      setPrice(2.00);
    } else if (type === "pendant") {
      setPrice(25 * Number(qty));
    }
  }, [type]);

  // create order mutation
  const [createOrder, { loading: orderLoading, error: orderError }] = useMutation(CREATE_ORDER);
  if (orderError) console.error(orderError);

  // find login user
  const { loading, error, data, refetch } = useQuery(ALL_USERS);
  if (error) console.error(error);
  useEffect(() => {
    if (localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email')) {
      if (data) {
        const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'));
        const userEmail = token.body.decodedToken.user.email
        setUserInfo(data?.user?.filter(user => user.email === userEmail)[0]);
      }
    }
  }, [data]);


  // Network Error
  useEffect(() => {
    if (errMessage) {
      setLoadingShaw(false);
      Notiflix.Notify.success(errMessage);
    }
  }, [errMessage]);


  // stripe payment
  const history = useHistory();
  const dispatch = useDispatch();

  const textInfo = useSelector((state) => {
    return state[textReducers.pendantTextInfo];
  });

  const { line_one, line_two, line_three } = textInfo;

  let clickPayment = async (token) => {

    try {
      let product = {
        price: (price + Number(charge)) * 100,
        name: "Pendant",
      };

      token.card = {
        ...token.card,
        address_city: user.city,
        name: user.name,
        address_line1: user.address,
        address_state: user.state,
        address_zip: user.zip
      }

      let body = {
        token,
        product,
      };

      await dispatch(makeStripePayment(body, history));
      setLoadingShaw(true)
    } catch (error) {
      console.error(error);
    }


  };

  useEffect(() => {
    if (previousProps.sucessPayment !== sucessPayment) {
      if (sucessPayment) {
        setLoadingShaw(false)
        props.history.push('/webThankyou');
      }
    }
    return () => {
      previousProps.sucessPayment = sucessPayment
    }
  }, [sucessPayment])

  useEffect(() => {
    if (paymentResponseStatus === 200) {
      successPayment();
    }
  }, [paymentResponseStatus]);

  const successPayment = async (e) => {
    await createOrder({
      variables: {
        charge_price: Number(price),
        charge_shipping: Number(charge),
        checkout_method: "card",
        userId: userInfo?.id,
        engraving_line_1_text: line_one,
        engraving_line_2_text: line_two,
        engraving_line_3_text: line_three,
        gateway_order_id: "",
        gateway_confirmation_id: "",
        quantity: Number(qty),
        ship_to_city: user.city,
        ship_to_country: 'USA',
        ship_to_state: user.state,
        ship_to_street1: user.address,
        ship_to_street2: user.address,
        ship_to_zip: user.zip,
        type: 'Pendant',
        status_id: 1
      }
    });

    // item, qty, address, price, sCharge, tCharge
    const fullAddress = `${user.address} ${user.city} ${user.state} ${user.zip}`;
    const totalPrice = Number(price + charge);
    const data = await {
      recipients: [user.email, 'orders@jelliepets.com'],
      subject: 'Jellie Id Order',
      body: jellieIdOrder('pendant', qty, fullAddress, (price).toFixed(2), (charge).toFixed(2), (totalPrice).toFixed(2))
    };

    await dispatch(emailActions.sendEmail(data));
    await dispatch(textActions.clearText());
    await dispatch(paymentActions.clearState());
  };

  function verifyEmail(value) {
    const emailRex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }

  function verifyZip(value) {
    const zipRex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    if (zipRex.test(value)) {
      return true
    }
    return false
  }

  return (
    <>
      {loadingShaw && <Loading />}
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container>
        <div className="shippinginfo_content mx-auto">
          <h2>Shipping info</h2>
          <p className="ship_to">Ship to</p>
          <Form className="shippinginfo_form">
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input
                required
                className="shippinginfo_formgroup-input"
                id="recipientname"
                autoComplete="off"
                name="name"
                type="text"
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
              <Label className="shippinginfo_formgroup-label" for="">
                Recipient name
              </Label>
            </FormGroup>{" "}
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input
                required
                className="shippinginfo_formgroup-input"
                id="recipientemail"
                name="email"
                autoComplete="off"
                type="text"
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value })
                  if (verifyEmail(e.target.value)) {
                    setErrEmail('')
                  } else {
                    setErrEmail('Enter Valid Email')
                  }
                }}
              />
              <Label className="shippinginfo_formgroup-label" for="">
                Recipient email
              </Label>
            </FormGroup>{" "}
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input
                required
                autoComplete="off"
                className="shippinginfo_formgroup-input"
                id="address"
                name="address"
                type="text"
                onChange={(e) => setUser({ ...user, address: e.target.value })}
              />
              <Label className="shippinginfo_formgroup-label" for="">
                Address
              </Label>
            </FormGroup>{" "}
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input
                required
                autoComplete="off"
                className="shippinginfo_formgroup-input"
                id="city"
                name="city"
                type="text"
                onChange={(e) => setUser({ ...user, city: e.target.value })}
              />
              <Label className="shippinginfo_formgroup-label" for="">
                City
              </Label>
            </FormGroup>{" "}
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input type="select" className="form-control shippinginfo_formgroup-input shippinginfo_formgroup-input2 dropdown_state" onChange={e => setUser({ ...user, state: e.target.value })}>
                {
                  usStates.map((data) => {
                    return (
                      <option key={data} value={data}>{data}</option>
                    )
                  })
                }
              </Input>
              <Label className="shippinginfo_formgroup-label" for="">
                State
              </Label>
              <svg
                className="customid_formgroup-label2 arrow"
                width="10"
                height="6"
                style={{ top: '20px' }}
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>
            </FormGroup>{" "}
            <FormGroup className="shippinginfo_formgroup" floating>
              <Input
                required
                autoComplete="off"
                className="shippinginfo_formgroup-input"
                id="zip"
                name="zip"
                maxLength="5"
                type="number"
                onChange={(e) => {
                  setUser({ ...user, zip: e.target.value })
                  if (verifyZip(e.target.value)) {
                    setErrZip('')
                  } else {
                    setErrZip('Enter Valid Zipcode')
                  }
                }}
              />
              <Label className="shippinginfo_formgroup-label" for="">
                Zip
              </Label>
            </FormGroup>{" "}
          </Form>
          <div className="shipping_amount_description">
            <p className="shipping_desc m-0">Product: ${price}.00</p>
            <p className="shipping_desc m-0">Shipping: $9.99</p>
            <p className="shipping_amount m-0">Total: ${(price + 9.99).toFixed(2)}</p>
            <p className="shipping_desc">
              Please allow up to 3 weeks for delivery
            </p>
          </div>
          <div className="shipping_payment">
            <p className="font-weight-bold">Pay now</p>
            {/* <div className="payment_options">
              <a className="paypal" href="#">
                <img src={paypal} alt="" srcset="" />
              </a>
              <a className="applepay" href="#">
                <img src={applepay} alt="" srcset="" />
              </a>
            </div> */}
            <div>

              <StripeCheckout
                token={clickPayment}
                stripeKey={
                  process.env.REACT_APP_STRIPE_PUBLIC_KEY
                }
                name="JELLIE"
                amount={(price + Number(charge)) * 100}
                description="Payment with Stripe"
                currency="USD"
                image='https://jellie-static-assets.s3.us-east-2.amazonaws.com/favicon.png'
              >

                <Button disabled={errEmail === "" && errZip === "" && user.name !== "" && user.email !== "" && user.address !== "" && user.city !== "" && user.state !== "" && user.zip !== "" ? false : true} className="pay_now">
                  Pay now
                </Button>

              </StripeCheckout>
            </div>
          </div>
          <p className="shipping_backstep"><Link to="/webCustomize/pendant">Go Back</Link></p>
        </div>
      </Container>
    </>
  );
};

export default ShippingInfo;
