import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import GetApp from "../get_app_id/GetApp";
import './webJellieId.css';
import STICKER from "../../assets/Sticker 1.png";
import SMALL_STICKER from "../../assets/Sticker Examples 1.png";
import PENDANT from "../../assets/SS Tag_2x_wshadow 3.png";
import PET_SS from "../../assets/JELLIE ID_Explanation graphic-01 1.png";
import LOST from "../../assets/icons/Icon_256w_Alert_Black.png";
import SHARING from "../../assets/icons/Group 1596.png";
import PRIVACY from "../../assets/icons/Group 1597.png";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

let WebJellieID = () => {

  const [selectType, setSelectType] = useState("");

  // const { logout } = useAuth0()

  useEffect(() => {
    sessionStorage.removeItem('data');
    if (!localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email')) {
      localStorage.removeItem('Authorization');
    }
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container className="jellieid_page">
        <Row>
          <Col className="head">
            <h2>JELLIE ID</h2>
            <p>Smart Accessories for pet identification</p>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="type">
              <div className="sticker mr-5" style={{ cursor: "pointer", border: `${selectType === 'sticker' ? '1px solid #000' : ''}` }} onClick={(e) => {
                setSelectType("sticker");
              }}>
                <p className="first_para">ID Sticker</p>
                <img src={STICKER} className="sticker_img" alt="sticker" />
                <img src={SMALL_STICKER} className="small_sticker" alt="stickers" />
                <p className="second_para">ID Sticker can be applied to any existing collar, harness, or other pet accessory.</p>
                <p className="third_para">$2.00 with shipping</p>
              </div>
              <div className="pendant" style={{ cursor: "pointer", border: `${selectType === 'pendant' ? '1px solid #000' : ''}` }} onClick={(e) => {
                setSelectType("pendant");
              }}>
                <p className="first_para">Custom Metal Pendant</p>
                <img src={PENDANT} className="pendant_img" alt="pendant" />
                <p className="second_para">Durable stainless steel pendant, custom engraved for your pet</p>
                <p className="third_para">$19.99 with shipping</p>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col>
            <div className="protect">
              <p>Each Jellie ID has a unique QR code that is linked only to your pet. Anyone smart phone camera can scans this code to access the pet’s public profile which is always up to date and secure.</p>
              <div>
                <img src={PET_SS} className="pet_ss" alt="pet" />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="three-col mt-4">
          <Col md="4" className="lost_pet text-center">
            <img src={LOST} style={{ width: "89.92px", height: "83.32px" }} alt="lost_pet" />
            <h5 className="text-left">Lost pets</h5>
            <p className="text-left">People who scan the ID will see if a pet is missing, get info on nearby shelters, as well as be able to contact pet parents. </p>
          </Col>
          <Col md="4" className="easy_sharing text-center">
            <img src={SHARING} style={{ width: "86.52px", height: "83.32px" }} alt="easy_sharing" />
            <h5 className="text-left">Easy Sharing</h5>
            <p className="text-left">The always up-to-date profile makes it easy for others to get info on your pet (bio, habits, etc.) without needing the Jellie App.</p>
          </Col>
          <Col md="4" className="privacy text-center">
            <img src={PRIVACY} style={{ width: "93.43px", height: "67.95px" }} alt="privacy" />
            <h5 className="text-left">Privacy</h5>
            <p className="text-left">As a Pet Parent you have full control over what is displayed on the pet’s profile and can track ID scans by using JELLIE app</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              // disabled={selectType === "" ? true : false} tag={Link} to={selectType === "sticker" ? `/webShipping/${selectType}` : `/webCustomize/${selectType}`} 
              tag={Link}
              to="/webCustomize/pendant"
              className="continue_btn bg-white"
              outline onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>
              Get a JELLIE ID
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>
            </Button>
          </Col>
        </Row>
      </Container>
      <div style={{ marginBottom: "200px" }}></div>
      {/* <Container className="get_app_id webjellie_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
    </React.Fragment>
  )
};

export default WebJellieID;