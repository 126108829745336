import React, { useEffect, useState } from "react";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import "./customizeid.css";
import pendant_img from "../../assets/images/pendant_img.png";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import * as textActions from '../../redux/text/textActions';
import * as textReducers from '../../redux/text/textReducers';

const CustomizeId = (props) => {
  const { history } = props
  // const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [third, setThird] = useState("");
  const [qty, setQty] = useState(1);

  const textInfo = useSelector((state) => {
    return state[textReducers.pendantTextInfo];
  });
  const { line_one, line_two, line_three, qty_Data } = textInfo;

  const token = localStorage.getItem('Authorization')
  const Data = JSON.parse(sessionStorage.getItem('data'))
  useEffect(() => {
    if (Data?.qty) {
      setName(Data.name)
      setNumber(Data.number)
      setThird(Data.third)
      setQty(Data.qty)
      history.push(`/webShipping/pendant/${Data.qty}`)
    } else {
      line_one && setName(line_one);
      line_two && setNumber(line_two);
      line_three && setThird(line_three);
      qty_Data && setQty(qty_Data);
    }
  }, [Data, line_one, line_two, line_three, qty_Data]);

  const handleNameInput = (e) => {
    setName(e.target.value);
  };
  const handleNumberInput = (e) => {
    setNumber(e.target.value);
  };
  const handleThirdInput = (e) => {
    setThird(e.target.value);
  };

  // auth0
  const { loginWithRedirect } = useAuth0();

  const checkout = async () => {
    await dispatch(textActions.addText({name, number, third, qty}));
    if (!token) {
      loginWithRedirect();
    } else {
      history.push(`/webShipping/pendant/${qty}`);
    }
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))
    if (token?.body?.id_token) {
      localStorage.setItem('Authorization', `Bearer ${token?.body?.id_token}`)
    }
  }, []);

  return (
    <>
      <div
        style={{ width: "100%", height: "10px", background: "#F5813C" }}
      ></div>
      <Container>
        <div className="custom_id_content mx-auto">
          <h2>Customize your ID</h2>
          <div className="custom_pendant_img">
            <img src={pendant_img} alt="pendant_id_img" />
            <div
              className={`pendant_text_${third === "" ? "mandatory" : "all"}`}
            >
              <span
                // className="pendant_text_primary" 
                className={`${number === "" && third === "" ? `only_first` : `pendant_text_primary`}`}>{name}</span>
              <span className="pendant_text_secondary">{number}</span>
              <span className="pendant_text_third">{third}</span>
              <span className="pendant_front_view">FRONT</span>
              <span className="pendant_back_view">BACK</span>
            </div>
          </div>
          <Form className="customid_form" onSubmit={checkout}>
            <FormGroup className="customid_formgroup" floating>
              <Input
                className="customid_formgroup-input"
                id="name"
                name="name"
                maxLength={15}
                autoComplete="off"
                type="text"
                onChange={handleNameInput}
                defaultValue={name}
              />
              <Label className="customid_formgroup-label" for="exampleEmail">
                Line 1
              </Label>
            </FormGroup>{" "}
            <FormGroup className="customid_formgroup" floating>
              <Input
                className="customid_formgroup-input"
                id="uniqueid"
                maxLength={15}
                name="uniqueid"
                autoComplete="off"
                type="text/number"
                onChange={handleNumberInput}
                defaultValue={number}
              />
              <Label className="customid_formgroup-label" for="examplePassword">
                Line 2
              </Label>
            </FormGroup>{" "}
            <FormGroup className="customid_formgroup" floating>
              <Input
                className="customid_formgroup-input"
                id="abc"
                name="xyz"
                autoComplete="off"
                maxLength={15}
                type="text/number"
                onChange={handleThirdInput}
                defaultValue={third}
              />
              <Label className="customid_formgroup-label" for="examplePassword">
                Line 3
              </Label>
            </FormGroup>{" "}
            <FormGroup className="customid_formgroup" floating>
              {/* <Input
                className="customid_formgroup-input"
                id="quantity"
                name="quantity"
                type="number"
                min="1"
                max="10"
                required
                onChange={e => setQty(e.target.value)}
              />
              <Label className="customid_formgroup-label quantity" for="quantity">
                Quantity
              </Label> */}
              <div className="mt-3">
                <Input defaultValue={qty} type="select" className="form-control customid_formgroup-input customid_formgroup-input-RTL remove_focus" style={{ height: "42px", borderRadius: "10px", border: "1px solid #E5E5E5" }} onChange={e => setQty(e.target.value)} >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Input>
                <svg
                  className="customid_formgroup-label2 arrow"
                  width="10"
                  height="6"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                    fill="#616161"
                  />
                </svg>
                <Label className="customid_formgroup-label quantity" for="quantity">
                  Quantity
                </Label>
              </div>
            </FormGroup>{" "}
            <Button
              className="custom_id_shipping_btn"
              outline
            // tag={Link}
            // to="/webShipping/pendant"
            >
              Checkout
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>
            </Button>
            <p>
              <Link to="/JellieID">Go Back</Link>
            </p>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CustomizeId;
