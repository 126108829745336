import { gql } from '@apollo/client';

// Get all users
export const ALL_USERS = gql`
  query {
    user {
      id
      email
      first_name
      last_name
    }
  }
`;