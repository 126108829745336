import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './view/landing/Landing';
import Navbar from './view/navbar/Navbar';
import Footer from "./view/footer/Footer";
import Contact from "./view/contact_follow/Contact";
import Legal from "./view/legal/Legal";
import Help from "./view/help/Help";
import InvalidId from "./view/invalid/InvalidId";
import InvalidUrl from "./view/invalid/InvalidUrl";
import About from "./view/about/About";
import Thankyou from "./view/jellieid/Thankyou";
import ShippingInfo from './view/jellieid/ShippingInfo';
import CustomizeId from "./view/jellieid/CustomizeId";
import WebJellieID from './view/jellieid/WebJellieId';
import PrivateRoute from './view/PrivateRoute';
//graphql
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';


// graphql
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
      return message
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_NODE_URL_ONE }),
]);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('Authorization');

  return {
    headers: {
      ...headers,
      Authorization: token ? token : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});



let App = () => {

  const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))
  useEffect(() => {
    if (token?.body?.id_token) {
      localStorage.setItem('Authorization', `Bearer ${token?.body?.id_token}`)
    } else {
      localStorage.removeItem('Authorization');
    }
  }, [token]);

  return (
    <React.Fragment>
      <ApolloProvider client={client}>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path='/' component={Landing} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/legal' component={Legal} />
            <Route exact path='/help' component={Help} />
            <Route exact path="/invalidid" component={InvalidId} />
            <Route exact path="/invalidurl" component={InvalidUrl} />
            <Route exact path="/about" component={About} />
            <Route exact path="/webThankyou" component={Thankyou} />
            <PrivateRoute exact path="/webShipping/pendant/:qty" component={ShippingInfo} />
            <Route exact path="/webCustomize/:type" component={CustomizeId} />
            <Route exact path="/JellieID" component={WebJellieID} />
            <Route path="*" component={InvalidUrl} />
          </Switch>
          <div className="getapp_wrapper"></div>
          <Footer />
        </Router>
      </ApolloProvider>
    </React.Fragment>
  )
};

export default App;