import React, { useEffect, useState } from "react";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import Follow from "./Follow";
import "./contact.css";
import GetApp from "../get_app_id/GetApp";
import GetId from "../get_app_id/GetId";
import ReCAPTCHA from "react-google-recaptcha";
import { contactConfirmation } from "./EmailTemplate";
import { useDispatch } from "react-redux";
import * as emailAction from '../../redux/email/emailActions';
import { useHistory } from "react-router-dom";

const Contact = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [verified, setVerified] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [valid, setValid] = useState(false);

  function onChange(value) {
    setVerified(true);
  };


  useEffect(() => {
    if (verified === false) {
      setValid(false);
    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      setValid(false);
    } else if (message.length < 2) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [email, verified, valid, message]);

  const sendMessage = async (e) => {
    e.preventDefault();
    const data = {
      recipients: [email, 'support@jelliepets.com'],
      subject: 'Message from Jellie Website',
      body: contactConfirmation(name, message)
    };
    await dispatch(emailAction.sendEmail(data));
    history.push('/');
  };


  return (
    <>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container fluid className="contactus">
        <div className="contactus_info mx-auto">
          <h2>Contact us</h2>
          <p>Help us provide better pet experiences.</p>
          <Form className="contactus_form" onSubmit={sendMessage}>
            <FormGroup className="contactus_formgroup" floating>
              <Input
                className="contactus_formgroup-input"
                id="name"
                name="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Label className="contactus_formgroup-label" for="exampleEmail">
                Name
              </Label>
            </FormGroup>{" "}
            <FormGroup className="contactus_formgroup" floating>
              <Input
                className="contactus_formgroup-input"
                id="email"
                name="email"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <Label
                className="contactus_formgroup-label"
                for="examplePassword"
              >
                Email address
              </Label>
            </FormGroup>{" "}
            <FormGroup className="contactus_textarea">
              <Label className="formgroup_textarea" for="exampleText">
                What’s on your mind?
              </Label>
              <Input
                id="message"
                name="text"
                type="textarea"
                rows={5}
                placeholder="Type your message here"
                required
                onChange={(e) => setMessage(e.target.value)}
                style={{ color: "#295E65", fontWeight: "bold" }}
              />
            </FormGroup>
            <div className="g-recaptcha mb-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} data-sitekey="your_site_key">
              <ReCAPTCHA className="g-recaptcha"
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onChange}
              />
            </div>
            <Button disabled={!valid} className="contactus_form-button bg-white" outline>
              Send message
            </Button>
          </Form>
        </div>
      </Container>
      <Follow />
    </>
  );
};

export default Contact;
