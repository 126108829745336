import React from "react";
import { Col, Container, Row } from "reactstrap";
import dog from "../../assets/images/dog.png";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png"; 
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import { Link } from "react-router-dom";
import "./about.css";

const About = () => {
  return (
    <>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container>
        <div className="about_heading mx-auto">
          <p className="txt">We love pets</p>
        </div>
      </Container>
      <Container className="d-flex">
        <div className="about_main_content mx-auto">
          <Row className="m-0">
            <Col lg={6} className="dog_img d-flex justify-content-center">
              <img src={dog} />
            </Col>
            <Col lg={6} className="p-0 my-auto">
              <ul className="txt1 my-auto p-0">
                Pets make us happy, keep us active, and are our best friends.
                However keeping them<strong className="txt6"> healthy </strong>
                and<strong className="txt6"> safe </strong>
                can be difficult. Each pet is unique making it challenging to
                understand their individual needs and behaviors.
                <br />
                <br />
                As a result, in the US:
                <div className="pl-4">
                  <li>
                    <strong className="txt6">
                      <b>60%</b>
                    </strong>{" "}
                    of dogs and cats are obese or overweight.
                  </li>
                </div>
                <div className="pl-4">
                  <li>
                    <strong className="txt6">8  million </strong>go missing each
                    year and never make it home.
                  </li>
                </div>
                <br />
                As pet parents, we found the existing tools and services for
                these problems to be expensive, difficult to use, and often were
                ineffective.
                <br />
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="about_quote mx-auto">
          <p className="txt mt-5 mb-0">Who we are</p>
          <p className="txt1">
            We are a group of pet parents, technologists, designers, and
            veterinary practioners with a goal of:
            <br />
            <br />
            <strong className="txt6">
              {" "}
              Improving pet wellbeing through accessible, intuitive technology.
            </strong>
          </p>
        </div>
      </Container>
      {/* <Container className="get_app_id about_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="about_get_id">
      <Link to='/JellieID' style={{ textDecoration: "none" }} onClick={(e) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }} >
        <div className="get_id p-4">
          <div className="paw_icon">
            <div>
              <img src={pawIcon} alt="jellie_app_paw_icon" />
            </div>
          </div>
          <div className="jellieid_info p-3">
            <h2 className="py-3">Get a JELLIE ID</h2>
            <p className="info_sm">
              The easiest way to share info or protect your pet.
            </p>
            <p className="info_lg py-3">
              This smart accessory is easiest way to share info or protect your
              pet.
            </p>
            <ul>
              <li>- Readable by any smartphone camera</li>
              <li>- Links to pet profile with all relevant bio info</li>
              <li>- Masked (secure) contact parents and host</li>
            </ul>
          </div>
          <div className="jellieid_keychain px-4">
            <img src={keychain} alt="jellieid_keychain" />
          </div>
        </div>
      </Link>
    </Container>
    </>
  );
};

export default About;
