import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "./landing.css";
import PET_IMAGE from "../../assets/image 112.png";
import PET_IMAGE2 from "../../assets/image 113.jpg";
import DOWNLOAD_LINK from "../../assets/image 96.png";
import HEALTHY_HABIT from "../../assets/Group 1188.png";
import RIGHT_FOOD from "../../assets/Group 1542.png";
import SIMPLIFY_HOSTING from "../../assets/Group 1541.png";
import SHARE_INFO from "../../assets/Group 1540.png";
import FRAME from "../../assets/Frame.png";
import QR from "../../assets/image 97.png";
import LINK from "../../assets/image 94.png";
import RECTANGLE from "../../assets/Rectangle 720.png";
import PENDANT from "../../assets/Pendant 001.png";
// import GetApp from "../get_app_id/GetApp";
// import GetId from "../get_app_id/GetId";
import SS1 from "../../assets/images/ss1.png";
import SS2 from "../../assets/images/ss2.png";
import SS3 from "../../assets/images/ss3.png";
import SS4 from "../../assets/images/ss4.png";
import SS5 from "../../assets/images/ss5.png";
import jellie_id_logo from "../../assets/jellie_id_logo.png";
import { Link, useHistory } from "react-router-dom";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png";
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import { useAuth0 } from "@auth0/auth0-react";

const Landing = (props) => {

  // const Data = JSON.parse(sessionStorage.getItem('data'))
  // useEffect(() => {
  //   if (token?.body?.id_token) {
  //     localStorage.setItem('Authorization', `Bearer ${token?.body?.id_token}`)
  //   }
  //   // else {
  //   //   localStorage.removeItem('Authorization');
  //   // }
  // }, []);
  const token = JSON.parse(localStorage.getItem("@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email"));

  useEffect(() => {
    if (token?.body?.id_token) {
      localStorage.setItem("Authorization", `Bearer ${token?.body?.id_token}`);
      // const data = JSON.parse(sessionStorage.getItem("data"));
      if (sessionStorage.getItem("data")) {
        props.history.push(`/webCustomize/pendant`);
      }
    } else {
      localStorage.removeItem("Authorization");
    }
  }, [token]);

  useEffect(() => {
    if (token?.body?.id_token) {
      localStorage.setItem("Authorization", `Bearer ${token?.body?.id_token}`);
    } else {
      localStorage.removeItem("Authorization");
    }
  }, [token]);

  const { logout } = useAuth0()

  // useEffect(() => {
  //   if (Data?.qty) {
  //     props.history.push('/webCustomize/pendant')
  //   }
  // }, [Data])

  return (
    <React.Fragment>
      <div
        style={{ background: "#F5813C" }}
        className="container-fluid landing_top"
      >
        <Row style={{ margin: "0", padding: "0" }}>
          <div className="box_one"></div>
          <div className="box_two">
            <p>
              <Link
                to="/JellieID"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <img src={jellie_id_logo} alt="jellie_id_logo" srcset="" />
              </Link>
            </p>
          </div>
        </Row>
      </div>
      <div>
        <img src={PET_IMAGE} className="landing_logo" alt="" />
        <img src={PET_IMAGE2} className="landing_logo2" alt="" />
      </div>
      <Container className="text_link">
        <Row>
          <Col lg="6" md="6" className="text_great_pet">
            <h4>
              Great Pet Parenting
            </h4>
          </Col>
          <Col lg="6" md="6" className="download_link">
            {/* <a href="https://www.apple.com/in/app-store/" target="_blank">
              <img src={DOWNLOAD_LINK} alt="" />
            </a> */}
          </Col>
        </Row>
      </Container>
      <Container className="second_section">
        <Row>
          <Col lg="3" md="6" className="healthy_habit">
            <img src={HEALTHY_HABIT} alt="healty_habit" />
            <div className="typography">
              <h6>Maintain healthy habits</h6>
              <p className="para_one">
                We help establish feeding and exercise habits to achieve healthy
                weight and possibly extend your pet's life.
              </p>
              <p className="para_two">Easy, scientific wellness management.</p>
            </div>
          </Col>
          <Col className="right_food" lg="3" md="6">
            <img src={RIGHT_FOOD} alt="right_food" />
            <div className="typography">
              <h6>Get the right food</h6>
              <p className="para_one">
                Learn what food is best for your pet and where you can get it by
                barcode scan or following our tailored recommendations.
              </p>
              <p className="para_two">
                Effective, responsible product shopping.
              </p>
            </div>
          </Col>
          <Col className="simplify_hosting" lg="3" md="6">
            <img src={SIMPLIFY_HOSTING} alt="simplify_hosting" />
            <div className="typography">
              <h6>Simplify hosting</h6>
              <p className="para_one">
                Assign hosts & co-parents that can centrally monitor pet needs,
                activities, or respod to rescue events.
              </p>
              <p className="para_two">Great parenting even when away</p>
            </div>
          </Col>
          <Col className="share_info" lg="3" md="6">
            <img src={SHARE_INFO} alt="share_info" />
            <div className="typography">
              <h6>Share info easily</h6>
              <p className="para_one">
                JELLIE Pets each have a unique QR code + a profile with medical,
                contacts, and other info controlled by parent.
              </p>
              <p className="para_two">Fast, secure info sharing and rescue</p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Mobile ScreenShort */}
      {/* for carousel landscape */}
      {/* <div className="container ssview">
        <div className="d-flex scroll-layout my-5 scr">
          <div style={{ marginRight: "111.95px" }} className="text-center mb-4">
            <img height="602px" width="278.02px" className="mb-4" src={SS1} />
            <div className="px-4" style={{ fontSize: "14px" }}>
              Track your pet’s activities automatically and easily
            </div>
          </div>
          <div style={{ marginRight: "111.95px" }} className="text-center mb-4">
            <img height="602px" width="278.02px" className="mb-4" src={SS2} />
            <div className="px-4" style={{ fontSize: "14px" }}>
              Your pet’s info in one place with secured access for others
            </div>
          </div>
          <div style={{ marginRight: "111.95px" }} className="text-center mb-4">
            <img height="602px" width="278.02px" className="mb-4" src={SS3} />
            <div className="px-4" style={{ fontSize: "14px" }}>
              Learn, catalog, or find places to buy your pet’s products
            </div>
          </div>
          <div style={{ marginRight: "111.95px" }} className="text-center mb-4">
            <img height="602px" width="278.02px" className="mb-4" src={SS4} />
            <div className="px-4" style={{ fontSize: "14px" }}>
              Create routines for you and others keep up with pet needs
            </div>
          </div>
          <div style={{ marginRight: "111.95px" }} className="text-center mb-4">
            <img height="602px" width="278.02px" className="mb-4" src={SS5} />
            <div className="px-4" style={{ fontSize: "14px" }}>
              Set food & exercise targets that keep your pet healthy
            </div>
          </div>
        </div>
      </div> */}
      {/* <!--for carousel mobile      --> */}
      {/* <div className="ssview1 w-100 text-center">
        <div id="demo" className="carousel slide" data-ride="carousel">
          <ul className="carousel-indicators" style={{ display: "none" }}>
            <li data-target="#demo" data-slide-to="0" className="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
            <li data-target="#demo" data-slide-to="3"></li>
            <li data-target="#demo" data-slide-to="4"></li>
          </ul>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img width="80%" src={SS1} alt="Screen 1" />
              <div className="px-4  mx-5 mt-4" style={{ fontSize: "14px" }}>
                Track your pet’s activities automatically and easily
              </div>
            </div>
            <div className="carousel-item">
              <img width="80%" src={SS2} alt="Screen 2" />
              <div className="px-4  mx-5 mt-4" style={{ fontSize: "14px" }}>
                Your pet’s info in one place with secured access for others
              </div>
            </div>
            <div className="carousel-item">
              <img width="80%" src={SS3} alt="Screen 3" />
              <div className="px-4  mx-5 mt-4" style={{ fontSize: "14px" }}>
                Learn, catalog, or find places to buy your pet’s products
              </div>
            </div>
            <div className="carousel-item">
              <img width="80%" src={SS4} alt="Screen 4" />
              <div className="px-4  mx-5 mt-4" style={{ fontSize: "14px" }}>
                Create routines for you and others keep up with pet needs
              </div>
            </div>
            <div className="carousel-item">
              <img width="80%" src={SS5} alt="Screen 5" />
              <div className="px-4  mx-5 mt-4" style={{ fontSize: "14px" }}>
                Set food & exercise targets that keep your pet healthy
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <Container className="get_app_id landing_get_app">
        <a
          href="https://www.apple.com/in/app-store/"
          target="_blank"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="landing_get_id">
        <Link
          to="/JellieID"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }}
        >
          <div className="get_id p-4">
            <div className="paw_icon">
              <div>
                <img src={pawIcon} alt="jellie_app_paw_icon" />
              </div>
            </div>
            <div className="jellieid_info p-3">
              <h2 className="py-3">Get a JELLIE ID</h2>
              <p className="info_sm">
                The easiest way to share info or protect your pet.
              </p>
              <p className="info_lg py-3">
                This smart accessory is easiest way to share info or protect
                your pet.
              </p>
              <ul>
                <li>- Readable by any smartphone camera</li>
                <li>- Links to pet profile with all relevant bio info</li>
                <li>- Masked (secure) contact parents and host</li>
              </ul>
            </div>
            <div className="jellieid_keychain px-4">
              <img src={keychain} alt="jellieid_keychain" />
            </div>
          </div>
        </Link>
      </Container>
    </React.Fragment>
  );
};

export default Landing;
