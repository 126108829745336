import React from "react";
import { Col, Container, Row } from "reactstrap";
import LOGO from '../../assets/Group 1370.png';
import { Link } from "react-router-dom";
import './navbar.css';

const Navbar = () => {
  return (
    <React.Fragment>
      <div className="nav_bar">
        <Container fluid>
          <Row>
            <Col>
              <div>
                <Row>
                  <Col lg='6' md='6' sm='12'>
                    <Link to="/" onClick={(e) => {
                      document.body.scrollTop = 0;
                      document.documentElement.scrollTop = 0;
                    }}><img src={LOGO} alt="jellie_logo" /></Link>
                  </Col>
                  <Col lg='6' md='6' className="col_icons">
                    <div className="float-right icons">
                      <a href="https://www.facebook.com/jelliepets/" target="_blank"><i className="fab fa-facebook nav_icon" style={{ color: '#fff' }}></i></a>
                      <a href="https://www.instagram.com/jelliepets/" target="_blank"><i className="fab fa-instagram nav_icon" style={{ color: '#fff' }}></i></a>
                      <a href="https://www.linkedin.com/company/jellie" target="_blank"><i className="fab fa-linkedin nav_icon" style={{ color: '#fff' }}></i></a>
                      <a href="https://twitter.com/JelliePets" target="_blank"><i className="fab fa-twitter nav_icon" style={{ color: '#fff' }}></i></a>
                      <a href="https://www.tiktok.com/@jelliepets" target="_blank"><i className="fab fa-tiktok nav_icon" style={{ color: '#fff' }}></i></a>
                      <a href="https://www.youtube.com/channel/UCBrWhPpw7mO59441hXhkUNg" target="_blank"><i className="fab fa-youtube nav_icon" style={{ color: '#fff' }}></i></a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default Navbar;