import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { Container } from "reactstrap";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png";
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import { Link } from "react-router-dom";
import "./legal.css";

const Legal = () => {
  return (
    <>
      <div
        style={{ width: "100%", height: "10px", background: "#F5813C" }}
      ></div>
      <Container fluid className="legal">
        <div className="legal_content mx-auto">
          <h2>Legal information</h2>
          <Collapsible
            trigger={[
              <p className="collapse_name">Privacy policy</p>,
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>,
            ]}
          >
            <div className="collapse_inner_content">
              <div className="profile_collapse_row">
                <span className="profile_collapse_row_content">
                  <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                    PRIVACY NOTICE
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Last updated May 01, 2021
                  </strong>
                  <br />
                  <br />
                  Thank you for choosing to be part of our community at Jellie,
                  Inc (<strong style={{ fontWeight: "bold" }}>"Company"</strong>
                  , <strong style={{ fontWeight: "bold" }}>"we"</strong>,
                  <strong style={{ fontWeight: "bold" }}> "us"</strong>,{" "}
                  <strong style={{ fontWeight: "bold" }}>"our"</strong>). We are
                  committed to protecting your personal information and your
                  right to privacy. If you have any questions or concerns about
                  this privacy notice, or our practices with regards to your
                  personal information, please contact us at info@jellie.io.
                  <br />
                  <br />
                  When you visit our website{" "}
                  <a target="_blank" href="https://jelliepets.com/">
                    http://www.jelliepets.com
                  </a>
                  (the<strong style={{ fontWeight: "bold" }}> "Website"</strong>
                  ), use our mobile application, as the case may be (the
                  <strong style={{ fontWeight: "bold" }}> "App"</strong>) and
                  more generally, use any of our services (the
                  <strong style={{ fontWeight: "bold" }}> "Services"</strong>,
                  which include the Website and App), we appreciate that you are
                  trusting us with your personal information. We take your
                  privacy very seriously. In this privacy notice, we seek to
                  explain to you in the clearest way possible what information
                  we collect, how we use it and what rights you have in relation
                  to it. We hope you take some time to read through it
                  carefully, as it is important. If there are any terms in this
                  privacy notice that you do not agree with, please discontinue
                  use of our Services immediately.
                  <br />
                  <br />
                  This privacy notice applies to all information collected
                  through our Services (which, as described above, includes our
                  Website and App), as well as, any related services, sales,
                  marketing or events.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Please read this privacy notice carefully as it will help
                    you understand what we do with the information that we
                    collect.
                  </strong>
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    TABLE OF CONTENTS
                  </div>
                  <br />
                  <a href="#11">
                    1. WHAT INFORMATION DO WE COLLECT?
                    <br />
                  </a>
                  <a href="#12">
                    2. HOW DO WE USE YOUR INFORMATION?
                    <br />
                  </a>
                  <a href="#13">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    <br />
                  </a>
                  <a href="#14">
                    4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                    <br />
                  </a>
                  <a href="#15">
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    <br />
                  </a>
                  <a href="#16">
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    <br />
                  </a>
                  <a href="#17">
                    7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                    <br />
                  </a>
                  <a href="#18">
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?
                    <br />
                  </a>
                  <a href="#19">
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    <br />
                  </a>
                  <a href="#110">
                    10. WHAT ARE YOUR PRIVACY RIGHTS?
                    <br />
                  </a>
                  <a href="#111">
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                    <br />
                  </a>
                  <a href="#112">
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    <br />
                  </a>
                  <a href="#113">
                    13. DO WE MAKE UPDATES TO THIS NOTICE?
                    <br />
                  </a>
                  <a href="#114">
                    14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    <br />
                  </a>
                  <a href="#115">
                    15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                    <br />
                    <br />
                  </a>
                  <div id={11} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </div>
                  <br />
                  Personal information you disclose to us
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  collect personal information that you provide to us.
                  <br />
                  <br />
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services
                  (such as by posting messages in our online forums or entering
                  competitions, contests or giveaways) or otherwise when you
                  contact us.
                  <br />
                  <br />
                  The personal information that we collect depends on the
                  context of your interactions with us and the Services, the
                  choices you make and the products and features you use. The
                  personal information we collect may include the following:
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Personal Information Provided by You.
                  </strong>{" "}
                  We collect names; phone numbers; email addresses; mailing
                  addresses; billing addresses; debit/credit card numbers;
                  social media pages; and other similar information.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Payment Data.
                  </strong>{" "}
                  We may collect data necessary to process your payment if you
                  make purchases, such as your payment instrument number (such
                  as a credit card number), and the security code associated
                  with your payment instrument. All payment data is stored by
                  PayPal. You may find their privacy notice link(s) here:{" "}
                  <a
                    target="_blank"
                    href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
                  >
                    https://www.paypal.com/us/webapps/mpp/ua/privacy-full.
                  </a>
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Social Media Login Data.
                  </strong>{" "}
                  We may provide you with the option to register with us using
                  your existing social media account details, like your
                  Facebook, Twitter or other social media account. If you choose
                  to register in this way, we will collect the information
                  described in the section called "
                  <a href="#16">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.
                  <br />
                  <br />
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes
                  to such personal information.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Information automatically collected
                  </strong>
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> Some
                  information — such as your Internet Protocol (IP) address
                  and/or browser and device characteristics — is collected
                  automatically when you visit our Services.
                  <br />
                  <br />
                  We automatically collect certain information when you visit,
                  use or navigate the Services. This information does not reveal
                  your specific identity (like your name or contact information)
                  but may include device and usage information, such as your IP
                  address, browser and device characteristics, operating system,
                  language preferences, referring URLs, device name, country,
                  location, information about how and when you use our Services
                  and other technical information. This information is primarily
                  needed to maintain the security and operation of our Services,
                  and for our internal analytics and reporting purposes.
                  <br />
                  <br />
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                  <br />
                  <br />
                  The information we collect includes:
                  <br />
                  <br />
                  ● Log and Usage Data. Log and usage data is service-related,
                  diagnostic, usage and performance information our servers
                  automatically collect when you access or use our Services and
                  which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address, device
                  information, browser type and settings and information about
                  your activity in the Services (such as the date/time stamps
                  associated with your usage, pages and files viewed, searches
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports (sometimes called 'crash dumps') and hardware
                  settings).
                  <br />
                  <br />
                  ● Device Data. We collect device data such as information
                  about your computer, phone, tablet or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification numbers,
                  location, browser type, hardware model Internet service
                  provider and/or mobile carrier, operating system and system
                  configuration information.
                  <br />
                  <br />
                  ● Location Data. We collect location data such as information
                  about your device's location, which can be either precise or
                  imprecise. How much information we collect depends on the type
                  and settings of the device you use to access the Services. For
                  example, we may use GPS and other technologies to collect
                  geolocation data that tells us your current location (based on
                  your IP address). You can opt out of allowing us to collect
                  this information either by refusing access to the information
                  or by disabling your Location setting on your device. Note
                  however, if you choose to opt out, you may not be able to use
                  certain aspects of the Services.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Information collected through our App
                  </strong>
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  collect information regarding your geolocation, mobile device,
                  push notifications, when you use our App.
                  <br />
                  <br />
                  If you use our App, we also collect the following information:
                  <br />
                  <br />
                  ● Geolocation Information. We may request access or permission
                  to and track location-based information from your mobile
                  device, either continuously or while you are using our App, to
                  provide certain location-based services. If you wish to change
                  our access or permissions, you may do so in your device's
                  settings.
                  <br />
                  <br />
                  ● Mobile Device Access. We may request access or permission to
                  certain features from your mobile device, including your
                  mobile device's reminders, camera, calendar, contacts,
                  microphone, sms messages, and other features. If you wish to
                  change our access or permissions, you may do so in your
                  device's settings.
                  <br />
                  <br />
                  ● Mobile Device Data. We automatically collect device
                  information (such as your mobile device ID, model and
                  manufacturer), operating system, version information and
                  system configuration information, device and application
                  identification numbers, browser type and version, hardware
                  model Internet service provider and/or mobile carrier, and
                  Internet Protocol (IP) address (or proxy server). If you are
                  using our App, we may also collect information about the phone
                  network associated with your mobile device, your mobile
                  device’s operating system or platform, the type of mobile
                  device you use, your mobile device’s unique device ID and
                  information about the features of our App you accessed.
                  <br />
                  <br />
                  ● Push Notifications. We may request to send you push
                  notifications regarding your account or certain features of
                  the App. If you wish to opt-out from receiving these types of
                  communications, you may turn them off in your device's
                  settings.
                  <br />
                  <br />
                  This information is primarily needed to maintain the security
                  and operation of our App, for troubleshooting and for our
                  internal analytics and reporting purposes.
                  <br />
                  <br />
                  <div id={12} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    2. HOW DO WE USE YOUR INFORMATION?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  process your information for purposes based on legitimate
                  business interests, the fulfillment of our contract with you,
                  compliance with our legal obligations, and/or your consent.
                  <br />
                  <br />
                  We use personal information collected via our Services for a
                  variety of business purposes described below. We process your
                  personal information for these purposes in reliance on our
                  legitimate business interests, in order to enter into or
                  perform a contract with you, with your consent, and/or for
                  compliance with our legal obligations. We indicate the
                  specific processing grounds we rely on next to each purpose
                  listed below.
                  <br />
                  <br />
                  We use the information we collect or receive:
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To facilitate account creation and logon process.
                  </strong>{" "}
                  If you choose to link your account with us to a third-party
                  account (such as your Google or Facebook account), we use the
                  information you allowed us to collect from those third parties
                  to facilitate account creation and logon process for the
                  performance of the contract. See the section below headed "
                  <a href="#16">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" for
                  further information.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To post testimonials.
                  </strong>{" "}
                  We post testimonials on our Services that may contain personal
                  information. Prior to posting a testimonial, we will obtain
                  your consent to use your name and the content of the
                  testimonial. If you wish to update, or delete your
                  testimonial, please contact us at jumie@jellie.io and be sure
                  to include your name, testimonial location, and contact
                  information.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Request feedback.
                  </strong>{" "}
                  We may use your information to request feedback and to contact
                  you about your use of our Services.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To enable user-to-user communications.
                  </strong>{" "}
                  We may use your information in order to enable user-to-user
                  communications with each user's consent.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To manage user accounts.
                  </strong>{" "}
                  We may use your information for the purposes of managing our
                  account and keeping it in working order.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To send administrative information to you.
                  </strong>{" "}
                  We may use your personal information to send you product,
                  service and new feature information and/or information about
                  changes to our terms, conditions, and policies.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To protect our Services.
                  </strong>{" "}
                  We may use your information as part of our efforts to keep our
                  Services safe and secure (for example, for fraud monitoring
                  and prevention).
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To enforce our terms, conditions and policies for business
                    purposes, to comply with legal and regulatory requirements
                    or in connection with our contract.
                  </strong>
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To respond to legal requests and prevent harm.
                  </strong>{" "}
                  If we receive a subpoena or other legal request, we may need
                  to inspect the data we hold to determine how to respond.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Fulfill and manage your orders.
                  </strong>{" "}
                  We may use your information to fulfill and manage your orders,
                  payments, returns, and exchanges made through the Services.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Administer prize draws and competitions.
                  </strong>{" "}
                  We may use your information to administer prize draws and
                  competitions when you elect to participate in our
                  competitions.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To deliver and facilitate delivery of services to the user.
                  </strong>
                  We may use your information to provide you with the requested
                  service.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To respond to user inquiries/offer support to users.
                  </strong>{" "}
                  We may use your information to respond to your inquiries and
                  solve any potential issues you might have with the use of our
                  Services.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    To send you marketing and promotional communications.
                  </strong>{" "}
                  We and/or our third-party marketing partners may use the
                  personal information you send to us for our marketing
                  purposes, if this is in accordance with your marketing
                  preferences. For example, when expressing an interest in
                  obtaining information about us or our Services, subscribing to
                  marketing or otherwise contacting us, we will collect personal
                  information from you. You can opt-out of our marketing emails
                  at any time (see the "
                  <a href="#110">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below).
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Deliver targeted advertising to you.
                  </strong>{" "}
                  We may use your information to develop and display
                  personalized content and advertising (and work with third
                  parties who do so) tailored to your interests and/or location
                  and to measure its effectiveness.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    For other business purposes.
                  </strong>{" "}
                  We may use your information for other business purposes, such
                  as data analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and
                  improve our Services, products, marketing and your experience.
                  We may use and store this information in aggregated and
                  anonymized form so that it is not associated with individual
                  end users and does not include personal information. We will
                  not use identifiable personal information without your
                  consent.
                  <br />
                  <br />
                  <div id={13} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  only share information with your consent, to comply with laws,
                  to provide you with services, to protect your rights, or to
                  fulfill business obligations.
                  <br />
                  <br />
                  We may process or share your data that we hold based on the
                  following legal basis:
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>Consent:</strong> We
                  may process your data if you have given us specific consent to
                  use your personal information for a specific purpose.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Legitimate Interests:
                  </strong>{" "}
                  We may process your data when it is reasonably necessary to
                  achieve our legitimate business interests.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Performance of a Contract:
                  </strong>{" "}
                  Where we have entered into a contract with you, we may process
                  your personal information to fulfill the terms of our
                  contract.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Legal Obligations:
                  </strong>{" "}
                  We may disclose your information where we are legally required
                  to do so in order to comply with applicable law, governmental
                  requests, a judicial proceeding, court order, or legal
                  process, such as in response to a court order or a subpoena
                  (including in response to public authorities to meet national
                  security or law enforcement requirements).
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Vital Interests:
                  </strong>{" "}
                  We may disclose your information where we believe it is
                  necessary to investigate, prevent, or take action regarding
                  potential violations of our policies, suspected fraud,
                  situations involving potential threats to the safety of any
                  person and illegal activities, or as evidence in litigation in
                  which we are involved. More specifically, we may need to
                  process your data or share your personal information in the
                  following situations:
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Business Transfers.
                  </strong>{" "}
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Google Maps Platform APIs.
                  </strong>{" "}
                  We may share your information with certain Google Maps
                  Platform APIs (e.g., Google Maps API, Place API). To find out
                  more about Google’s Privacy Policy, please refer to this{" "}
                  <a target="_blank" href="https://policies.google.com/privacy">
                    link
                  </a>
                  . We obtain and store on your device ('cache') your location
                  for six (6) months. You may revoke your consent anytime by
                  contacting us at the contact details provided at the end of
                  this document.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Vendors, Consultants and Other Third-Party Service
                    Providers.
                  </strong>
                  We may share your data with third-party vendors, service
                  providers, contractors or agents who perform services for us
                  or on our behalf and require access to such information to do
                  that work. Examples include: payment processing, data
                  analysis, email delivery, hosting services, customer service
                  and marketing efforts. We may allow selected third parties to
                  use tracking technology on the Services, which will enable
                  them to collect data on our behalf about how you interact with
                  our Services over time. This information may be used to, among
                  other things, analyze and track data, determine the popularity
                  of certain content, pages or features, and better understand
                  online activity. Unless described in this notice, we do not
                  share, sell, rent or trade any of your information with third
                  parties for their promotional purposes.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Third-Party Advertisers.
                  </strong>{" "}
                  We may use third-party advertising companies to serve ads when
                  you visit or use the Services. These companies may use
                  information about your visits to our Website(s) and other
                  websites that are contained in web cookies and other tracking
                  technologies in order to provide advertisements about goods
                  and services of interest to you.
                  <br />
                  <br />●{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    Offer Wall.
                  </strong>{" "}
                  Our App may display a third-party hosted "offer wall." Such an
                  offer wall allows third-party advertisers to offer virtual
                  currency, gifts, or other items to users in return for the
                  acceptance and completion of an advertisement offer. Such an
                  offer wall may appear in our App and be displayed to you based
                  on certain data, such as your geographic area or demographic
                  information. When you click on an offer wall, you will be
                  brought to an external website belonging to other persons and
                  will leave our App. A unique identifier, such as your user ID,
                  will be shared with the offer wall provider in order to
                  prevent fraud and properly credit your account with the
                  relevant reward. Please note that we do not control
                  third-party websites and have no responsibility in relation to
                  the content of such websites. The inclusion of a link towards
                  a third-party website does not imply an endorsement by us of
                  such website. Accordingly, we do not make any warranty
                  regarding such third-party websites and we will not be liable
                  for any loss or damage caused by the use of such websites. In
                  addition, when you access any third-party website, please
                  understand that your rights while accessing and using those
                  websites will be governed by the privacy notice and terms of
                  service relating to the use of those websites.
                  <br />
                  <br />
                  <div id={14} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    4. WHO WILL YOUR INFORMATION BE SHARED WITH?{" "}
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  only share information with the following categories of third
                  parties.
                  <br />
                  <br />
                  We only share and disclose your information with the following
                  categories of third parties. If we have processed your data
                  based on your consent and you wish to revoke your consent,
                  please contact us using the contact details provided in the
                  section below titled{" "}
                  <a href="#114">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                  ".
                  <br />
                  <br />
                  ● Ad Networks
                  <br />
                  <br />
                  ● Affiliate Marketing Programs
                  <br />
                  <br />
                  ● Sales &amp; Marketing Tools
                  <br />
                  <br />
                  ● Website Hosting Service Providers
                  <br />
                  <br />
                  ● Data Analytics Services
                  <br />
                  <br />
                  ● Data Storage Service Providers
                  <br />
                  <br />
                  ● Cloud Computing Services
                  <br />
                  <br />
                  ● Order Fulfillment Service Providers
                  <br />
                  <br />
                  ● Payment Processors
                  <br />
                  <br />
                  ● Performance Monitoring Tools
                  <br />
                  <br />
                  ● Retargeting Platforms
                  <br />
                  <br />
                  ● Social Networks
                  <br />
                  <br />
                  ● Testing Tools
                  <br />
                  <br />
                  ● User Account Registration &amp; Authentication Services
                  <br />
                  <br />
                  ● Communication &amp; Collaboration Tools
                  <br />
                  <br />
                  <div id={15} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  may use cookies and other tracking technologies to collect and
                  store your information.
                  <br />
                  <br />
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Notice.
                  <br />
                  <br />
                  <div id={16} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> If
                  you choose to register or log in to our services using a
                  social media account, we may have access to certain
                  information about you.
                  <br />
                  <br />
                  Our Services offers you the ability to register and login
                  using your third-party social media account details (like your
                  Facebook or Twitter logins). Where you choose to do this, we
                  will receive certain profile information about you from your
                  social media provider. The profile information we receive may
                  vary depending on the social media provider concerned, but
                  will often include your name, email address, friends list,
                  profile picture as well as other information you choose to
                  make public on such social media platform.
                  <br />
                  <br />
                  We will use the information we receive only for the purposes
                  that are described in this privacy notice or that are
                  otherwise made clear to you on the relevant Services. Please
                  note that we do not control, and are not responsible for,
                  other uses of your personal information by your third-party
                  social media provider. We recommend that you review their
                  privacy notice to understand how they collect, use and share
                  your personal information, and how you can set your privacy
                  preferences on their sites and apps.
                  <br />
                  <br />
                  <div id={17} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  are not responsible for the safety of any information that you
                  share with third-party providers who advertise, but are not
                  affiliated with, our Website.
                  <br />
                  <br />
                  The Services may contain advertisements from third parties
                  that are not affiliated with us and which may link to other
                  websites, online services or mobile applications. We cannot
                  guarantee the safety and privacy of data you provide to any
                  third parties. Any data collected by third parties is not
                  covered by this privacy notice. We are not responsible for the
                  content or privacy and security practices and policies of any
                  third parties, including other websites, services or
                  applications that may be linked to or from the Services. You
                  should review the policies of such third parties and contact
                  them directly to respond to your questions.
                  <br />
                  <br />
                  <div id={18} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  keep your information for as long as necessary to fulfill the
                  purposes outlined in this privacy notice unless otherwise
                  required by law.
                  <br />
                  <br />
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting or other legal requirements). No
                  purpose in this notice will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                  <br />
                  <br />
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup
                  archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                  <br />
                  <br />
                  <div id={19} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> We
                  aim to protect your personal information through a system of
                  organizational and technical security measures.
                  <br />
                  <br />
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, despite our
                  safeguards and efforts to secure your information, no
                  electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security, and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                  <br />
                  <br />
                  <div
                    id={110}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    10. WHAT ARE YOUR PRIVACY RIGHTS?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> You
                  may review, change, or terminate your account at any time.
                  <br />
                  <br />
                  If you are a resident in the EEA or UK and you believe we are
                  unlawfully processing your personal information, you also have
                  the right to complain to your local data protection
                  supervisory authority. You can find their contact details
                  here:
                  <a
                    target="_blank"
                    href="https://ec.europa.eu/newsroom/article29/items/612080"
                  >
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                  </a>
                  <br />
                  <br />
                  If you are a resident in Switzerland, the contact details for
                  the data protection authorities are available here:{" "}
                  <a
                    target="_blank"
                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  >
                    https://www.edoeb.admin.ch/edoeb/en/home.html.
                  </a>
                  <br />
                  <br />
                  If you have questions or comments about your privacy rights,
                  you may email us at info@jellie.io.
                  <br />
                  <br />
                  Account Information
                  <br />
                  <br />
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                  <br />
                  <br />
                  ● Log in to your account settings and update your user
                  account.
                  <br />
                  <br />
                  ● Contact us using the contact information provided.
                  <br />
                  <br />
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our Terms of Use and/or comply
                  with applicable legal requirements.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Cookies and similar technologies:
                  </strong>{" "}
                  Most Web browsers are set to accept cookies by default. If you
                  prefer, you can usually choose to set your browser to remove
                  cookies and to reject cookies. If you choose to remove cookies
                  or reject cookies, this could affect certain features or
                  services of our Services. To opt-out of interest-based
                  advertising by advertisers on our Services visit
                  <a
                    target="_blank"
                    href="https://optout.aboutads.info/?c=2&lang=EN"
                  >
                    http://www.aboutads.info/choices/
                  </a>
                  .<br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Opting out of email marketing:
                  </strong>{" "}
                  You can unsubscribe from our marketing email list at any time
                  by clicking on the unsubscribe link in the emails that we send
                  or by contacting us using the details provided below. You will
                  then be removed from the marketing email list — however, we
                  may still communicate with you, for example to send you
                  service-related emails that are necessary for the
                  administration and use of your account, to respond to service
                  requests, or for other non-marketing purposes. To otherwise
                  opt-out, you may:
                  <br />
                  <br />
                  ● Access your account settings and update your preferences.
                  <br />
                  <br />
                  ● Contact us using the contact information provided.
                  <br />
                  <br />
                  <div
                    id={111}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </div>
                  <br />
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                  <br />
                  <br />
                  <div
                    id={112}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> Yes,
                  if you are a resident of California, you are granted specific
                  rights regarding access to your personal information.
                  <br />
                  <br />
                  California Civil Code Section 1798.83, also known as the
                  "Shine The Light" law, permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                  <br />
                  <br />
                  If you are under 18 years of age, reside in California, and
                  have a registered account with a Service, you have the right
                  to request removal of unwanted data that you publicly post on
                  the Services. To request removal of such data, please contact
                  us using the contact information provided below, and include
                  the email address associated with your account and a statement
                  that you reside in California. We will make sure the data is
                  not publicly displayed on the Services, but please be aware
                  that the data may not be completely or comprehensively removed
                  from all our systems (e.g. backups, etc.).
                  <br />
                  <br />
                  CCPA Privacy Notice
                  <br />
                  <br />
                  The California Code of Regulations defines a "resident" as:
                  <br />
                  <br />
                  (1) every individual who is in the State of California for
                  other than a temporary or transitory purpose and (2) every
                  individual who is domiciled in the State of California who is
                  outside the State of California for a temporary or transitory
                  purpose
                  <br />
                  <br />
                  All other individuals are defined as "non-residents."
                  <br />
                  <br />
                  If this definition of "resident" applies to you, we must
                  adhere to certain rights and obligations regarding your
                  personal information.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    What categories of personal information do we collect?
                  </strong>
                  <br />
                  <br />
                  We have collected the following categories of personal
                  information in the past twelve (12) months:
                  <br />
                  <br />
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Examples</th>
                        <th>Collected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>A. Identifiers</td>
                        <td>
                          Contact details, such as real name, alias, postal
                          address, telephone or mobile contact number, unique
                          personal identifier, online identifier, Internet
                          Protocol address, email address and account name
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          B. Personal information categories listed in the
                          California Customer Records statute
                        </td>
                        <td>
                          Name, contact information, education, employment,
                          employment history and financial information
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          C. Protected classification characteristics under
                          California or federal law
                        </td>
                        <td>Gender and date of birth</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>D. Commercial information</td>
                        <td>
                          Transaction information, purchase history, financial
                          details and payment information
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>E. Biometric information</td>
                        <td>Fingerprints and voiceprints</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>F. Internet or other similar network activity</td>
                        <td>
                          Browsing history, search history, online behavior,
                          interest data, and interactions with our and other
                          websites, applications, systems and advertisements
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>G. Geolocation data</td>
                        <td>Device location</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          H. Audio, electronic, visual, thermal, olfactory, or
                          similar information
                        </td>
                        <td>
                          Images and audio, video or call recordings created in
                          connection with our business activities
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          I. Professional or employment-related information
                        </td>
                        <td>
                          Business contact details in order to provide you our
                          services at a business level, job title as well as
                          work history and professional qualifications if you
                          apply for a job with us
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>J. Education Information</td>
                        <td>Student records and directory information</td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>
                          K. Inferences drawn from other personal information
                        </td>
                        <td>
                          Inferences drawn from any of the collected personal
                          information listed above to create a profile or
                          summary about, for example, an individual’s
                          preferences and characteristics
                        </td>
                        <td>YES</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />
                  We may also collect other personal information outside of
                  these categories instances where you interact with us
                  in-person, online, or by phone or mail in the context of:
                  <br />
                  <br />
                  ● Receiving help through our customer support channels;
                  <br />
                  <br />
                  ● Participation in customer surveys or contests; and
                  <br />
                  <br />
                  ● Facilitation in the delivery of our Services and to respond
                  to your inquiries.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    How do we use and share your personal information?
                  </strong>
                  <br />
                  <br />
                  Jellie, Inc collects and shares your personal information
                  through:
                  <br />
                  <br />
                  ● Targeting cookies/Marketing cookies
                  <br />
                  <br />
                  ● Social media cookies
                  <br />
                  <br />
                  ● Beacons/Pixels/Tags
                  <br />
                  <br />
                  More information about our data collection and sharing
                  practices can be found in this privacy notice.
                  <br />
                  <br />
                  You may contact us by email at info@jellie.io, or by referring
                  to the contact details at the bottom of this document.
                  <br />
                  <br />
                  If you are using an authorized agent to exercise your right to
                  opt-out we may deny a request if the authorized agent does not
                  submit proof that they have been validly authorized to act on
                  your behalf.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Will your information be shared with anyone else?
                  </strong>
                  <br />
                  <br />
                  We may disclose your personal information with our service
                  providers pursuant to a written contract between us and each
                  service provider. Each service provider is a for-profit entity
                  that processes the information on our behalf.
                  <br />
                  <br />
                  We may use your personal information for our own business
                  purposes, such as for undertaking internal research for
                  technological development and demonstration. This is not
                  considered to be "selling" of your personal data.
                  <br />
                  <br />
                  Jellie, Inc has disclosed the following categories of personal
                  information to third parties for a business or commercial
                  purpose in the preceding twelve (12) months:
                  <br />
                  <br />
                  ● Category B. Personal information, as defined in the
                  California Customer Records law, such as your name, contact
                  information, education, employment, employment history and
                  financial information.
                  <br />
                  <br />
                  The categories of third parties to whom we disclosed personal
                  information for a business or commercial purpose can be found
                  under "
                  <a href="#114">WHO WILL YOUR INFORMATION BE SHARED WITH?</a>".
                  <br />
                  <br />
                  Jellie, Inc has not sold any personal information to third
                  parties for a business or commercial purpose in the preceding
                  twelve (12) months. Jellie, Inc will not sell personal
                  information in the future belonging to website visitors, users
                  and other consumers.
                  <br />
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Your rights with respect to your personal data
                  </strong>
                  <br />
                  <br />
                  Right to request deletion of the data - Request to delete
                  <br />
                  <br />
                  You can ask for the deletion of your personal information. If
                  you ask us to delete your personal information, we will
                  respect your request and delete your personal information,
                  subject to certain exceptions provided by law, such as (but
                  not limited to) the exercise by another consumer of his or her
                  right to free speech, our compliance requirements resulting
                  from a legal obligation or any processing that may be required
                  to protect against illegal activities.
                  <br />
                  <br />
                  Right to be informed - Request to know
                  <br />
                  <br />
                  Depending on the circumstances, you have a right to know:
                  <br />
                  <br />
                  ● whether we collect and use your personal information;
                  <br />
                  <br />
                  ● the categories of personal information that we collect;
                  <br />
                  <br />
                  ● the purposes for which the collected personal information is
                  used;
                  <br />
                  <br />
                  ● whether we sell your personal information to third parties;
                  <br />
                  <br />
                  ● the categories of personal information that we sold or
                  disclosed for a business purpose;
                  <br />
                  <br />
                  ● the categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose; and
                  <br />
                  <br />
                  ● the business or commercial purpose for collecting or selling
                  personal information.
                  <br />
                  <br />
                  In accordance with applicable law, we are not obligated to
                  provide or delete consumer information that is de-identified
                  in response to a consumer request or to re-identify individual
                  data to verify a consumer request.
                  <br />
                  <br />
                  Right to Non-Discrimination for the Exercise of a Consumer’s
                  Privacy Rights
                  <br />
                  <br />
                  We will not discriminate against you if you exercise your
                  privacy rights.
                  <br />
                  <br />
                  Verification process
                  <br />
                  <br />
                  Upon receiving your request, we will need to verify your
                  identity to determine you are the same person about whom we
                  have the information in our system. These verification efforts
                  require us to ask you to provide information so that we can
                  match it with information you have previously provided us. For
                  instance, depending on the type of request you submit, we may
                  ask you to provide certain information so that we can match
                  the information you provide with the information we already
                  have on file, or we may contact you through a communication
                  method (e.g. phone or email) that you have previously provided
                  to us. We may also use other verification methods as the
                  circumstances dictate.
                  <br />
                  <br />
                  We will only use personal information provided in your request
                  to verify your identity or authority to make the request. To
                  the extent possible, we will avoid requesting additional
                  information from you for the purposes of verification. If,
                  however, we cannot verify your identity from the information
                  already maintained by us, we may request that you provide
                  additional information for the purposes of verifying your
                  identity, and for security or fraud-prevention purposes. We
                  will delete such additionally provided information as soon as
                  we finish verifying you.
                  <br />
                  <br />
                  Other privacy rights
                  <br />
                  <br />
                  ● you may object to the processing of your personal data
                  <br />
                  <br />
                  ● you may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the data
                  <br />
                  <br />
                  ● you can designate an authorized agent to make a request
                  under the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                  <br />
                  <br />
                  ● you may request to opt-out from future selling of your
                  personal information to third parties. Upon receiving a
                  request to opt-out, we will act upon the request as soon as
                  feasibly possible, but no later than 15 days from the date of
                  the request submission.
                  <br />
                  <br />
                  To exercise these rights, you can contact us by email at
                  info@jellie.io, or by referring to the contact details at the
                  bottom of this document. If you have a complaint about how we
                  handle your data, we would like to hear from you. <br />
                  <br />
                  <div
                    id={113}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    13. DO WE MAKE UPDATES TO THIS NOTICE?
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>In Short:</strong> Yes,
                  we will update this notice as necessary to stay compliant with
                  relevant laws.
                  <br />
                  <br />
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                  <br />
                  <br />
                  <div
                    id={114}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
                  </div>
                  <br />
                  If you have questions or comments about this notice, you may
                  contact our Data Protection Officer (DPO), Jumie Yuventi, by
                  email at jumie@jellie.io, by phone at 1-844-518-6720, or by
                  post to:
                  <br />
                  <br />
                  Jellie, Inc
                  <br />
                  Jumie Yuventi
                  <br />
                  251 LITTLE FALLS DRIVE
                  <br />
                  WILMINGTON, DE 19808
                  <br />
                  United States
                  <br />
                  <br />
                  <div
                    id={115}
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </div>
                  <br />
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it in some
                  circumstances. To request to review, update, or delete your
                  personal information, please submit a request form by clicking{" "}
                  <a
                    target="_blank"
                    href="https://app.termly.io/notify/68bfb312-a2d6-421c-a13f-27334f798347"
                  >
                    here
                  </a>
                  .
                </span>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              <p className="collapse_name">Terms & conditions</p>,
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>,
            ]}
          >
            <div className="collapse_inner_content">
              <div className="profile_collapse_row">
                <span className="profile_collapse_row_content">
                  <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                    END USER LICENSE AGREEMENT
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Last updated May 01, 2021
                  </strong>
                  <br />
                  <br />
                  Jellie is licensed to You (End-User) by Jellie, Inc, located
                  at 251 LITTLE FALLS DRIVE, WILMINGTON, Delaware 19808, United
                  States (hereinafter: Licensor), for use only under the terms
                  of this License Agreement.
                  <br />
                  <br />
                  By downloading the Application from the Apple AppStore, and
                  any update thereto (as permitted by this License Agreement),
                  You indicate that You agree to be bound by all of the terms
                  and conditions of this License Agreement, and that You accept
                  this License Agreement.
                  <br />
                  <br />
                  The parties of this License Agreement acknowledge that Apple
                  is not a Party to this License Agreement and is not bound by
                  any provisions or obligations with regard to the Application,
                  such as warranty, liability, maintenance and support thereof.
                  Jellie, Inc, not Apple, is solely responsible for the licensed
                  Application and the content thereof.
                  <br />
                  <br />
                  This License Agreement may not provide for usage rules for the
                  Application that are in conflict with the latest
                  <a
                    target="_blank"
                    href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
                  >
                    {" "}
                    App Store Terms of Service.
                  </a>{" "}
                  Jellie, Inc acknowledges that it had the opportunity to review
                  said terms and this License Agreement is not conflicting with
                  them.
                  <br />
                  <br />
                  All rights not expressly granted to You are reserved.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    1. THE APPLICATION
                  </div>
                  <br />
                  Jellie (hereinafter: Application) is a piece of software
                  created to Helps pet parents easily set and maintain healthy
                  habits, get the right food, simplify hosting/co-parenting, and
                  share information about their pet. - and customized for Apple
                  mobile devices. It is used to Track health events (e.g.
                  exercise and feeding) and food products used on a pet by any
                  host or parent and communicate the impact of these events on
                  health targets and projected weight tailored for each pet..
                  <br />
                  <br />
                  Furthermore, it is used to Obtain recommendations for exercise
                  routines, feeding routines and pet weight based on breed, age,
                  and other factors, Assign/manage hosts and co-parents for a
                  pet, Create pet profiles that can be shared easily via JELLIE
                  ID (unique QR codes per pet available in digital and physical
                  formats) and Mark pets as missing/found to accelerate search
                  &amp; rescue via JELLIE ID and app.
                  <br />
                  <br />
                  The Application is not tailored to comply with
                  industry-specific regulations (Health Insurance Portability
                  and Accountability Act (HIPAA), Federal Information Security
                  Management Act (FISMA), etc.), so if your interactions would
                  be subjected to such laws, you may not use this Application.
                  You may not use the Application in a way that would violate
                  the Gramm-Leach-Bliley Act (GLBA).
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    2. SCOPE OF LICENSE
                  </div>
                  <br />
                  2.1 You are given a non-transferable, non-exclusive,
                  non-sublicensable license to install and use the Licensed
                  Application on any Apple-branded Products that You (End-User)
                  own or control and as permitted by the Usage Rules set forth
                  in this section and the App Store Terms of Service, with the
                  exception that such licensed Application may be accessed and
                  used by other accounts associated with You (End-User, The
                  Purchaser) via Family Sharing or volume purchasing.
                  <br />
                  <br />
                  2.2 This license will also govern any updates of the
                  Application provided by Licensor that replace, repair, and/or
                  supplement the first Application, unless a separate license is
                  provided for such update in which case the terms of that new
                  license will govern.
                  <br />
                  <br />
                  2.3 You may not share or make the Application available to
                  third parties (unless to the degree allowed by the Apple Terms
                  and Conditions, and with Jellie, Inc's prior written consent),
                  sell, rent, lend, lease or otherwise redistribute the
                  Application.
                  <br />
                  <br />
                  2.4 You may not reverse engineer, translate, disassemble,
                  integrate, decompile, integrate, remove, modify, combine,
                  create derivative works or updates of, adapt, or attempt to
                  derive the source code of the Application, or any part thereof
                  (except with Jellie, Inc's prior written consent).
                  <br />
                  <br />
                  2.5 You may not copy (excluding when expressly authorized by
                  this license and the Usage Rules) or alter the Application or
                  portions thereof. You may create and store copies only on
                  devices that You own or control for backup keeping under the
                  terms of this license, the App Store Terms of Service, and any
                  other terms and conditions that apply to the device or
                  software used. You may not remove any intellectual property
                  notices. You acknowledge that no unauthorized third parties
                  may gain access to these copies at any time.
                  <br />
                  <br />
                  2.6 Violations of the obligations mentioned above, as well as
                  the attempt of such infringement, may be subject to
                  prosecution and damages.
                  <br />
                  <br />
                  2.7 Licensor reserves the right to modify the terms and
                  conditions of licensing.
                  <br />
                  <br />
                  2.8 Nothing in this license should be interpreted to restrict
                  third-party terms. When using the Application, You must ensure
                  that You comply with applicable third-party terms and
                  conditions.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    3. TECHNICAL REQUIREMENTS
                  </div>
                  <br />
                  3.1 Licensor attempts to keep the Application updated so that
                  it complies with modified/new versions of the firmware and new
                  hardware. You are not granted rights to claim such an update.
                  <br />
                  <br />
                  3.2 You acknowledge that it is Your responsibility to confirm
                  and determine that the app end-user device on which You intend
                  to use the Application satisfies the technical specifications
                  mentioned above.
                  <br />
                  <br />
                  3.3 Licensor reserves the right to modify the technical
                  specifications as it sees appropriate at any time.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    4. MAINTENANCE AND SUPPORT
                  </div>
                  <br />
                  4.1 The Licensor is solely responsible for providing any
                  maintenance and support services for this licensed
                  Application. You can reach the Licensor at the email address
                  listed in the App Store Overview for this licensed
                  Application.
                  <br />
                  <br />
                  4.2 Jellie, Inc and the End-User acknowledge that Apple has no
                  obligation whatsoever to furnish any maintenance and support
                  services with respect to the licensed Application.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    5. USE OF DATA
                  </div>
                  <br />
                  You acknowledge that Licensor will be able to access and
                  adjust Your downloaded licensed Application content and Your
                  personal information, and that Licensor's use of such material
                  and information is subject to Your legal agreements with
                  Licensor and Licensor's privacy policy:{" "}
                  <a
                    target="_blank"
                    href="https://app.termly.io/document/privacy-policy/68bfb312-a2d6-421c-a13f-27334f798347"
                  >
                    https://app.termly.io/document/privacy-policy/68bfb312-a2d6-421c-a13f-27334f798347.
                  </a>
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    6. USER GENERATED CONTRIBUTIONS
                  </div>
                  <br />
                  The Application may invite you to chat, contribute to, or
                  participate in blogs, message boards, online forums, and other
                  functionality, and may provide you with the opportunity to
                  create, submit, post, display, transmit, perform, publish,
                  distribute, or broadcast content and materials to us or in the
                  Application, including but not limited to text, writings,
                  video, audio, photographs, graphics, comments, suggestions, or
                  personal information or other material (collectively,
                  "Contributions"). Contributions may be viewable by other users
                  of the Application and through third-party websites or
                  applications. As such, any Contributions you transmit may be
                  treated as non-confidential and non-proprietary. When you
                  create or make available any Contributions, you thereby
                  represent and warrant that:
                  <br />
                  <br />
                  1. The creation, distribution, transmission, public display,
                  or performance, and the accessing, downloading, or copying of
                  your Contributions do not and will not infringe the
                  proprietary rights, including but not limited to the
                  copyright, patent, trademark, trade secret, or moral rights of
                  any third party.
                  <br />
                  2. You are the creator and owner of or have the necessary
                  licenses, rights, consents, releases, and permissions to use
                  and to authorize us, the Application, and other users of the
                  Application to use your Contributions in any manner
                  contemplated by the Application and these Terms of Use.
                  <br />
                  3. You have the written consent, release, and/or permission of
                  each and every identifiable individual person in your
                  Contributions to use the name or likeness or each and every
                  such identifiable individual person to enable inclusion and
                  use of your Contributions in any manner contemplated by the
                  Application and these Terms of Use.
                  <br />
                  4. Your Contributions are not false, inaccurate, or
                  misleading.
                  <br />
                  5. Your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of solicitation.
                  <br />
                  6. Your Contributions are not obscene, lewd, lascivious,
                  filthy, violent, harassing, libelous, slanderous, or otherwise
                  objectionable (as determined by us).
                  <br />
                  7. Your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.
                  <br />
                  8. Your Contributions are not used to harass or threaten (in
                  the legal sense of those terms) any other person and to
                  promote violence against a specific person or class of people.
                  <br />
                  9. Your Contributions do not violate any applicable law,
                  regulation, or rule.
                  <br />
                  10. Your Contributions do not violate the privacy or publicity
                  rights of any third party.
                  <br />
                  11. Your Contributions do not contain any material that
                  solicits personal information from anyone under the age of 18
                  or exploits people under the age of 18 in a sexual or violent
                  manner.
                  <br />
                  12. Your Contributions do not violate any applicable law
                  concerning child pornography, or otherwise intended to protect
                  the health or well-being of minors.
                  <br />
                  13. Your Contributions do not include any offensive comments
                  that are connected to race, national origin, gender, sexual
                  preference, or physical handicap.
                  <br />
                  14. Your Contributions do not otherwise violate, or link to
                  material that violates, any provision of these Terms of Use,
                  or any applicable law or regulation.
                  <br />
                  <br />
                  Any use of the Application in violation of the foregoing
                  violates these Terms of Use and may result in, among other
                  things, termination or suspension of your rights to use the
                  Application.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    7. CONTRIBUTION LICENSE
                  </div>
                  <br />
                  By posting your Contributions to any part of the Application
                  or making Contributions accessible to the Application by
                  linking your account from the Application to any of your
                  social networking accounts, you automatically grant, and you
                  represent and warrant that you have the right to grant, to us
                  an unrestricted, unlimited, irrevocable, perpetual,
                  non-exclusive, transferable, royalty-free, fully-paid,
                  worldwide right, and license to host, use copy, reproduce,
                  disclose, sell, resell, publish, broad cast, retitle, archive,
                  store, cache, publicly display, reformat, translate, transmit,
                  excerpt (in whole or in part), and distribute such
                  Contributions (including, without limitation, your image and
                  voice) for any purpose, commercial advertising, or otherwise,
                  and to prepare derivative works of, or incorporate in other
                  works, such as Contributions, and grant and authorize
                  sublicenses of the foregoing. The use and distribution may
                  occur in any media formats and through any media channels.
                  <br />
                  <br />
                  This license will apply to any form, media, or technology now
                  known or hereafter developed, and includes our use of your
                  name, company name, and franchise name, as applicable, and any
                  of the trademarks, service marks, trade names, logos, and
                  personal and commercial images you provide. You waive all
                  moral rights in your Contributions, and you warrant that moral
                  rights have not otherwise been asserted in your Contributions.
                  <br />
                  <br />
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area in the Application. You are solely
                  responsible for your Contributions to the Application and you
                  expressly agree to exonerate us from any and all
                  responsibility and to refrain from any legal action against us
                  regarding your Contributions.
                  <br />
                  <br />
                  We have the right, in our sole and absolute discretion, (1) to
                  edit, redact, or otherwise change any Contributions; (2) to
                  re-categorize any Contributions to place them in more
                  appropriate locations in the Application; and (3) to
                  pre-screen or delete any Contributions at any time and for any
                  reason, without notice. We have no obligation to monitor your
                  Contributions.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    8. LIABILITY
                  </div>
                  <br />
                  8.1 Licensor's responsibility in the case of violation of
                  obligations and tort shall be limited to intent and gross
                  negligence. Only in case of a breach of essential contractual
                  duties (cardinal obligations), Licensor shall also be liable
                  in case of slight negligence. In any case, liability shall be
                  limited to the foreseeable, contractually typical damages. The
                  limitation mentioned above does not apply to injuries to life,
                  limb, or health.
                  <br />
                  <br />
                  8.2 Licensor takes no accountability or responsibility for any
                  damages caused due to a breach of duties according to Section
                  2 of this Agreement. To avoid data loss, You are required to
                  make use of backup functions of the Application to the extent
                  allowed by applicable third-party terms and conditions of use.
                  You are aware that in case of alterations or manipulations of
                  the Application, You will not have access to licensed
                  Application.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    9. WARRANTY
                  </div>
                  <br />
                  9.1 Licensor warrants that the Application is free of spyware,
                  trojan horses, viruses, or any other malware at the time of
                  Your download. Licensor warrants that the Application works as
                  described in the user documentation.
                  <br />
                  <br />
                  9.2 No warranty is provided for the Application that is not
                  executable on the device, that has been unauthorizedly
                  modified, handled inappropriately or culpably, combined or
                  installed with inappropriate hardware or software, used with
                  inappropriate accessories, regardless if by Yourself or by
                  third parties, or if there are any other reasons outside of
                  Jellie, Inc's sphere of influence that affect the
                  executability of the Application.
                  <br />
                  <br />
                  9.3 You are required to inspect the Application immediately
                  after installing it and notify Jellie, Inc about issues
                  discovered without delay by e-mail provided in{" "}
                  <a href="#10">Product Claims</a>. The defect report will be
                  taken into consideration and further investigated if it has
                  been mailed within a period of __________ days after
                  discovery.
                  <br />
                  <br />
                  9.4 If we confirm that the Application is defective, Jellie,
                  Inc reserves a choice to remedy the situation either by means
                  of solving the defect or substitute delivery.
                  <br />
                  <br />
                  9.5 In the event of any failure of the Application to conform
                  to any applicable warranty, You may notify the
                  App-Store-Operator, and Your Application purchase price will
                  be refunded to You. To the maximum extent permitted by
                  applicable law, the App-Store-Operator will have no other
                  warranty obligation whatsoever with respect to the App, and
                  any other losses, claims, damages, liabilities, expenses and
                  costs attributable to any negligence to adhere to any
                  warranty.
                  <br />
                  <br />
                  9.6 If the user is an entrepreneur, any claim based on faults
                  expires after a statutory period of limitation amounting to
                  twelve (12) months after the Application was made available to
                  the user. The statutory periods of limitation given by law
                  apply for users who are consumers.
                  <br />
                  <br />
                  <div id={10} style={{ fontSize: "19px", fontWeight: "bold" }}>
                    10. PRODUCT CLAIMS
                  </div>
                  <br />
                  Jellie, Inc and the End-User acknowledge that Jellie, Inc, and
                  not Apple, is responsible for addressing any claims of the
                  End-User or any third party relating to the licensed
                  Application or the End-User’s possession and/or use of that
                  licensed Application, including, but not limited to:
                  <br />
                  <br />
                  (i) product liability claims;
                  <br />
                  <br />
                  (ii) any claim that the licensed Application fails to conform
                  to any applicable legal or regulatory requirement; and
                  <br />
                  <br />
                  (iii) claims arising under consumer protection, privacy, or
                  similar legislation, including in connection with Your
                  Licensed Application’s use of the HealthKit and HomeKit.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    11. LEGAL COMPLIANCE
                  </div>
                  <br />
                  You represent and warrant that You are not located in a
                  country that is subject to a U.S. Government embargo, or that
                  has been designated by the U.S. Government as a "terrorist
                  supporting" country; and that You are not listed on any U.S.
                  Government list of prohibited or restricted parties.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    12. CONTACT INFORMATION{" "}
                  </div>
                  <br />
                  For general inquiries, complaints, questions or claims
                  concerning the licensed Application, please contact:
                  <br />
                  <br />
                  Jellie team
                  <br />
                  6001 W Parmer Ln
                  <br />
                  Ste 370 PMB 6948
                  <br />
                  Austin, TX 78727
                  <br />
                  United States
                  <br />
                  info@jellie.io
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    13. TERMINATION
                  </div>
                  <br />
                  The license is valid until terminated by Jellie, Inc or by
                  You. Your rights under this license will terminate
                  automatically and without notice from Jellie, Inc if You fail
                  to adhere to any term(s) of this license. Upon License
                  termination, You shall stop all use of the Application, and
                  destroy all copies, full or partial, of the Application.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
                  </div>
                  <br />
                  Jellie, Inc represents and warrants that Jellie, Inc will
                  comply with applicable third-party terms of agreement when
                  using licensed Application.
                  <br />
                  <br />
                  In Accordance with Section 9 of the "Instructions for Minimum
                  Terms of Developer's End-User License Agreement," Apple and
                  Apple's subsidiaries shall be third-party beneficiaries of
                  this End User License Agreement and - upon Your acceptance of
                  the terms and conditions of this license agreement, Apple will
                  have the right (and will be deemed to have accepted the right)
                  to enforce this End User License Agreement against You as a
                  third-party beneficiary thereof.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    15. INTELLECTUAL PROPERTY RIGHTS
                  </div>
                  <br />
                  Jellie, Inc and the End-User acknowledge that, in the event of
                  any third-party claim that the licensed Application or the
                  End-User's possession and use of that licensed Application
                  infringes on the third party's intellectual property rights,
                  Jellie, Inc, and not Apple, will be solely responsible for the
                  investigation, defense, settlement and discharge or any such
                  intellectual property infringement claims.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    16. APPLICABLE LAW
                  </div>
                  <br />
                  This license agreement is governed by the laws of the State of
                  Delaware excluding its conflicts of law rules.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    17. MISCELLANEOUS
                  </div>
                  <br />
                  17.1 If any of the terms of this agreement should be or become
                  invalid, the validity of the remaining provisions shall not be
                  affected. Invalid terms will be replaced by valid ones
                  formulated in a way that will achieve the primary purpose.
                  <br />
                  <br />
                  17.2 Collateral agreements, changes and amendments are only
                  valid if laid down in writing. The preceding clause can only
                  be waived in writing.
                </span>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              <p className="collapse_name">Content Disclaimers</p>,
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>,
            ]}
          >
            <div className="collapse_inner_content">
              <div className="profile_collapse_row">
                <span className="profile_collapse_row_content">
                  <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                    DISCLAIMER
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Last updated May 01, 2021
                  </strong>
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    WEBSITE DISCLAIMER
                  </div>
                  <br />
                  The information provided by Jellie, Inc (“we,” “us” or “our”)
                  on{" "}
                  <a href="https://jelliepets.com/">
                    {" "}
                    https://jellipets.com/
                  </a>{" "}
                  (the “Site”) and our mobile application is for general
                  informational purposes only. All information on the Site and
                  our mobile application is provided in good faith, however we
                  make no representation or warranty of any kind, express or
                  implied, regarding the accuracy, adequacy, validity,
                  reliability, availability or completeness of any information
                  on the Site or our mobile application. UNDER NO CIRCUMSTANCE
                  SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF
                  ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR
                  MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON
                  THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND
                  OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON
                  THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN
                  RISK.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    EXTERNAL LINKS DISCLAIMER
                  </div>
                  <br />
                  The Site and our mobile application may contain (or you may be
                  sent through the Site or our mobile application) links to
                  other websites or content belonging to or originating from
                  third parties or links to websites and features in banners or
                  other advertising. Such external links are not investigated,
                  monitored, or checked for accuracy, adequacy, validity,
                  reliability, availability or completeness by us. WE DO NOT
                  WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
                  ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                  THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
                  FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT
                  BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                  TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS
                  OR SERVICES.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    PROFESSIONAL DISCLAIMER
                  </div>
                  <br />
                  The Site cannot and does not contain fitness advice. The
                  fitness information is provided for general informational and
                  educational purposes only and is not a substitute for
                  professional advice. Accordingly, before taking any actions
                  based upon such information, we encourage you to consult with
                  the appropriate professionals. We do not provide any kind of
                  fitness advice. THE USE OR RELIANCE OF ANY INFORMATION
                  CONTAINED ON THIS SITE OR OUR MOBILE APPLICATION IS SOLELY AT
                  YOUR OWN RISK.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    AFFILIATES DISCLAIMER
                  </div>
                  <br />
                  The Site and our mobile application may contain links to
                  affiliate websites, and we receive an affiliate commission for
                  any purchases made by you on the affiliate website using such
                  links. Our affiliates include the following:
                  <br />
                  <br />
                  &nbsp;&nbsp; ● eBay Partner Network
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    TESTIMONIALS DISCLAIMER
                  </div>
                  <br />
                  The Site may contain testimonials by users of our products
                  and/or services. These testimonials reflect the real-life
                  experiences and opinions of such users. However, the
                  experiences are personal to those particular users, and may
                  not necessarily be representative of all users of our products
                  and/or services. We do not claim, and you should not assume,
                  that all users will have the same experiences. YOUR INDIVIDUAL
                  RESULTS MAY VARY.
                  <br />
                  <br />
                  The testimonials on the Site are submitted in various forms
                  such as text, audio and/or video, and are reviewed by us
                  before being posted. They appear on the Site verbatim as given
                  by the users, except for the correction of grammar or typing
                  errors. Some testimonials may have been shortened for the sake
                  of brevity where the full testimonial contained extraneous
                  information not relevant to the general public.
                  <br />
                  <br />
                  The views and opinions contained in the testimonials belong
                  solely to the individual user and do not reflect our views and
                  opinions. We are not affiliated with users who provide
                  testimonials, and users are not paid or otherwise compensated
                  for their testimonials.
                  <br />
                  <br />
                  The testimonials on the Site are not intended, nor should they
                  be construed, as claims that our products and/or services can
                  be used to diagnose, treat, mitigate, cure, prevent or
                  otherwise be used for any disease or medical condition. No
                  testimonials have been clinically proven or evaluated.
                </span>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              <p className="collapse_name">Returns Policy</p>,
              <svg
                className="down_arrow"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                  fill="#616161"
                />
              </svg>,
            ]}
          >
            <div className="collapse_inner_content">
              <div className="profile_collapse_row">
                <span className="profile_collapse_row_content">
                  <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                    RETURN POLICY
                  </div>
                  <br />
                  <strong style={{ fontWeight: "bold" }}>
                    Last updated May 01, 2021
                  </strong>
                  <br />
                  <br />
                  Thank you for your purchase. We hope you are happy with your
                  purchase. However, if you are not completely satisfied with
                  your purchase for any reason, you may return it to us for a
                  full refund only. Please see below for more information on our
                  return policy.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    RETURNS
                  </div>
                  <br />
                  All returns must be postmarked within thirty (30) days of the
                  purchase date. All returned items must be in new and unused
                  condition, with all original tags and labels attached.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    RETURN PROCESS
                  </div>
                  <br />
                  To return an item, please email customer service at
                  info@jellie.io to obtain a Return Merchandise Authorization
                  (RMA) number. After receiving a RMA number, place the item
                  securely in its original packaging and the return form
                  provided, and mail your return to the following address:
                  <br />
                  <br />
                  JELLIE Inc
                  <br />
                  Attn: Returns
                  <br />
                  RMA #<br />
                  6001 W Parmer Ln <br />
                  Ste 370 PMB 6948 <br />
                  Austin, TX 78727 <br />
                  United States
                  <br />
                  <br />
                  Please note, you will be responsible for all return shipping
                  charges. We strongly recommend that you use a trackable method
                  to mail your return.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    REFUNDS
                  </div>
                  <br />
                  After receiving your return and inspecting the condition of
                  your item, we will process your return. Please allow at least
                  thirty (30) days from the receipt of your item to process your
                  return. Refunds may take 1-2 billing cycles to appear on your
                  credit card statement, depending on your credit card company.
                  We will notify you by email when your return has been
                  processed.
                  <br />
                  <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    EXCEPTIONS
                  </div>
                  <br />
                  The following items cannot be returned:
                  <br />
                  <br />
                  ● Jellie ID Stickers <br />
                  ● __________ <br />
                  <br />
                  For defective or damaged products, please contact us at the
                  contact details below to arrange a refund or exchange.
                  <br /> <br />
                  <div style={{ fontSize: "19px", fontWeight: "bold" }}>
                    QUESTIONS
                  </div>
                  <br />
                  If you have any questions concerning our return policy, please
                  contact us at: info@jellie.io
                </span>
              </div>
            </div>
          </Collapsible>
        </div>
      </Container>
      <div style={{ marginBottom: "200px" }}></div>
      {/* <Container className="get_app_id legal_get_app">
        <a
          href="https://www.apple.com/in/app-store/"
          target="_blank"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="legal_get_id">
        <Link
          to="/JellieID"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }}
        >
          <div className="get_id p-4">
            <div className="paw_icon">
              <div>
                <img src={pawIcon} alt="jellie_app_paw_icon" />
              </div>
            </div>
            <div className="jellieid_info p-3">
              <h2 className="py-3">Get a JELLIE ID</h2>
              <p className="info_sm">
                The easiest way to share info or protect your pet.
              </p>
              <p className="info_lg py-3">
                This smart accessory is easiest way to share info or protect
                your pet.
              </p>
              <ul>
                <li>- Readable by any smartphone camera</li>
                <li>- Links to pet profile with all relevant bio info</li>
                <li>- Masked (secure) contact parents and host</li>
              </ul>
            </div>
            <div className="jellieid_keychain px-4">
              <img src={keychain} alt="jellieid_keychain" />
            </div>
          </div>
        </Link>
      </Container>
    </>
  );
};

export default Legal;
