import axios from 'axios';

export const STRIPE_PAYMENT_REQUEST = 'STRIPE_PAYMENT_REQUEST';
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_FAILURE = 'STRIPE_PAYMENT_FAILURE';

export const CLEAR_STRIPE_PAYMENT = 'CLEAR_STRIPE_PAYMENT';
export const CLEAR_STATE = 'CLEAR_STATE';

export const makeStripePayment = (body, history) => {
    return async (dispatch) => {
        dispatch({ type: STRIPE_PAYMENT_REQUEST });
        dispatch({ type: CLEAR_STRIPE_PAYMENT });
        axios.post(`${process.env.REACT_APP_PAYMENT_BASE_URL}/api/v1/payment/pay`, body).then((response) => {
            dispatch({
                type: STRIPE_PAYMENT_SUCCESS,
                payload: response
            })
        }).catch((error) => {
            dispatch({
                type: STRIPE_PAYMENT_FAILURE,
                payload: error
            })
        })
        // try {
        //     dispatch({ type: STRIPE_PAYMENT_REQUEST });
        //     let dataUrl = `${process.env.REACT_APP_PAYMENT_BASE_URL}/api/v1/payment/pay`;
        //     let response = await axios.post(dataUrl, body);
        //     dispatch({ type: CLEAR_STRIPE_PAYMENT });
        //     dispatch({ type: STRIPE_PAYMENT_SUCCESS, payload: response });
        //     history.push('/webThankyou');
        // } catch (error) {
        //     console.error(error);
        //     dispatch({ type: STRIPE_PAYMENT_FAILURE, payload: error });
        // }
    }
};


export const clearState = () => {
    return async (dispatch) => {
        dispatch({type: CLEAR_STATE})
    }
};