import React from "react";
import { Container } from "reactstrap";
import fb from "../../assets/icons/fb.png";
import insta from "../../assets/icons/insta.png";
import linked from "../../assets/icons/linked.png";
import twitter from "../../assets/icons/twitter.png";
import tiktok from "../../assets/icons/tiktok.png";
import yt from "../../assets/icons/yt.png";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png"; 
import pawIcon from "../../assets/images/jellie_paw_icon.png";
import keychain from "../../assets/images/keychain.png";
import { Link } from "react-router-dom";
import "./follow.css";

const Follow = () => {
  return (
    <>
      <Container fluid className="followus">
        <div className="followus_info mx-auto">
          <h2>Follow us!</h2>
          <div className="icons">
            <div className="follow_facebook icon">
              <a href="https://www.facebook.com/jelliepets/" target="_blank"><img src={fb} alt="" /></a>
            </div>
            <div className="follow_insta icon">
              <a href="https://www.instagram.com/jelliepets/" target="_blank"><img src={insta} alt="" /></a>
            </div>
            <div className="follow_linkedin icon">
              <a href="https://www.linkedin.com/company/jellie" target="_blank"><img src={linked} alt="" /></a>
            </div>
            <div className="follow_twitter icon">
              <a href="https://twitter.com/JelliePets" target="_blank"><img src={twitter} alt="" /></a>
            </div>
            <div className="follow_tiktok icon">
              <a href="https://www.tiktok.com/@jelliepets" target="_blank"><img src={tiktok} alt="" /></a>
            </div>
            <div className="follow_youtube icon">
              <a href="https://www.youtube.com/channel/UCBrWhPpw7mO59441hXhkUNg" target="_blank"><img src={yt} alt="" /></a>
            </div>
          </div>
        </div>
      </Container>
      <div style={{marginBottom: "100px"}}></div>
      {/* <Container className="get_app_id follow_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <Container className="follow_get_id">
      <Link to='/JellieID' style={{ textDecoration: "none" }} onClick={(e) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }} >
        <div className="get_id p-4">
          <div className="paw_icon">
            <div>
              <img src={pawIcon} alt="jellie_app_paw_icon" />
            </div>
          </div>
          <div className="jellieid_info p-3">
            <h2 className="py-3">Get a JELLIE ID</h2>
            <p className="info_sm">
              The easiest way to share info or protect your pet.
            </p>
            <p className="info_lg py-3">
              This smart accessory is easiest way to share info or protect your
              pet.
            </p>
            <ul>
              <li>- Readable by any smartphone camera</li>
              <li>- Links to pet profile with all relevant bio info</li>
              <li>- Masked (secure) contact parents and host</li>
            </ul>
          </div>
          <div className="jellieid_keychain px-4">
            <img src={keychain} alt="jellieid_keychain" />
          </div>
        </div>
      </Link>
    </Container>
    </>
  );
};

export default Follow;
