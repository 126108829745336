import { gql } from '@apollo/client';


export const CREATE_ORDER = gql`
    mutation (
        $charge_price: float8!
        $charge_shipping: float8!
        $checkout_method: String!
        $userId: Int!
        $engraving_line_1_text: String
        $engraving_line_2_text: String
        $engraving_line_3_text: String
        $gateway_order_id: String!
        $gateway_confirmation_id: String!
        $quantity: Int!
        $ship_to_city: String!
        $ship_to_country: String!
        $ship_to_state: String!
        $ship_to_street1: String!
        $ship_to_street2: String!
        $ship_to_zip: String!
        $type: String!
        $status_id: Int!
    ) {
        insert_orders_one (object: {
            charge_price: $charge_price
            charge_shipping: $charge_shipping
            charge_taxes: 0
            checkout_method: $checkout_method
            created_by: $userId
            engraving_line_1_text: $engraving_line_1_text
            engraving_line_2_text: $engraving_line_2_text
            engraving_line_3_text: $engraving_line_3_text
            gateway_order_id: $gateway_order_id
            gateway_confirmation_id: $gateway_confirmation_id
            quantity: $quantity
            ship_to_city: $ship_to_city
            ship_to_country: $ship_to_country
            ship_to_state: $ship_to_state
            ship_to_street1: $ship_to_street1
            ship_to_street2: $ship_to_street2
            ship_to_zip: $ship_to_zip
            type: $type
            status_id: $status_id
        }) {
            id
        }
    }
`;
