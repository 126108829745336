import * as paymentActions from './paymentActions';

export const paymentFeatureKey = 'paymentInfo';

let initialState = {
    loading: false,
    errMessage: "",
    resStatus: null,
    sucess: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case paymentActions.STRIPE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            };

        case paymentActions.STRIPE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                resStatus: payload.status,
                sucess: true
            };

        case paymentActions.STRIPE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload.message
            }

        case paymentActions.CLEAR_STRIPE_PAYMENT:
            return {
                ...state,
                resStatus: null,
                sucess: null,
            }

        case paymentActions.CLEAR_STATE:
            return {
                ...state,
                sucess: null,
                resStatus: null,
                loading: false,
                errMessage: ""
            }

        default: return state;
    }
};