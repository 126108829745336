import { combineReducers } from 'redux';
import * as paymentReducers from './payment/paymentReducers';
import * as userAddressReducers from './userAddress/userAddressReducers';
import * as emailReducers from './email/emailReducers';
import * as textReducers from './text/textReducers';

export const rootReducer = combineReducers({
    [paymentReducers.paymentFeatureKey]: paymentReducers.reducer,
    [userAddressReducers.userAddressFeatureKey]: userAddressReducers.reducer,
    [emailReducers.emailFeatureKey]: emailReducers.reducer,
    [textReducers.pendantTextInfo]: textReducers.reducer
});