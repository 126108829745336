import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import jellieIcon from "../../assets/images/jellie_appicon.png";
import jellieQr from "../../assets/images/jellie_qr.png";
import androidIos from "../../assets/images/android_ios.png";
import "./thankyou.css";
import { useDispatch } from "react-redux";
import * as textActions from '../../redux/text/textActions';

const Thankyou = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(textActions.clearText())
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: "10px", background: "#F5813C" }}></div>
      <Container>
        <div className="thankyou_content">
          <h2>Thank you for your order!</h2>
          <p>Please check your email for confirmation</p>
          <Button tag={Link} to="/" className="return_home_button p-4" onClick={(e) => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }} >
            Return home{" "}
            <svg
              className="down_arrow"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                fill="#616161"
              />
            </svg>
          </Button>
        </div>
      </Container>
      {/* <Container className="get_app_id webjellie_get_app">
        <a href="https://www.apple.com/in/app-store/" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
          <div className="get_app p-4">
            <div>
              <div className="jelle_appicon">
                <img src={jellieIcon} alt="jellie_app_icon" />
              </div>
              <h2>
                <span className="get">Get</span>
                <span className="download">Download</span> the JELLIE App
              </h2>
            </div>
            <div>
              <div className="jellie_qrcode">
                <img src={jellieQr} alt="jellie_qr_code" />
              </div>
              <span className="scan">Scan QR for quick access</span>
              <div className="download_android_ios">
                <img src={androidIos} alt="download_for_android_ios" />
              </div>
            </div>
          </div>
        </a>
      </Container> */}
      <div className="getapp_wrapper"></div>
    </>
  );
};

export default Thankyou;
