import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <Container className="p-0">
          <ul className="footer_links p-0">
            <li>
              <Link to="/about" onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>About</Link>
            </li>
            <li>
              <Link to="/contact" onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>Contact</Link>
            </li>
            <li>
              <Link to="/help" onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>Help</Link>
            </li>
            <li>
              <Link to="/legal" onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>Legal</Link>
            </li>
          </ul>
          <ul className="footer_links_sm p-0">
            <li>
              <Link to="/legal" onClick={(e) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>Legal</Link>
            </li>
          </ul>
          <p className="right_reserved_quote">© 2021 All rights reserved</p>
        </Container>
      </div>
    </>
  );
};

export default Footer;
